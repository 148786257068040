import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAIL,
  GET_ACCOUNTS_DETAIL_SUCCESS,
  GET_ACCOUNTS_DETAIL,
  GET_ACCOUNTS_DETAIL_FAIL,
  CREATE_ACCOUNTS_SUCCESS,
  CREATE_ACCOUNTS_FAIL,
  CREATE_ACCOUNTS,
  UPDATE_ACCOUNTS,
  UPDATE_ACCOUNTS_SUCCESS,
  UPDATE_ACCOUNTS_FAIL,
  DELETE_ACCOUNTS_SUCCESS,
  DELETE_ACCOUNTS_FAIL,
  GET_ACCOUNT_CATEGORY,
  GET_ACCOUNT_CATEGORY_DETAIL,
  CREATE_ACCOUNT_CATEGORY,
  UPDATE_ACCOUNT_CATEGORY,
  GET_ACCOUNT_CATEGORY_SUCCESS,
  GET_ACCOUNT_CATEGORY_DETAIL_SUCCESS,
  GET_ACCOUNT_CATEGORY_DETAIL_FAIL,
  CREATE_ACCOUNT_CATEGORY_SUCCESS,
  CREATE_ACCOUNT_CATEGORY_FAIL,
  UPDATE_ACCOUNT_CATEGORY_SUCCESS,
  UPDATE_ACCOUNT_CATEGORY_FAIL,
  DELETE_ACCOUNT_CATEGORY_SUCCESS,
  DELETE_ACCOUNT_CATEGORY_FAIL,
  GET_ACCOUNT_CATEGORY_FAIL,
  GET_ACCOUNT_INCOME_SUCCESS,
  GET_ACCOUNTS_EXPENSE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  accounts: [],
  accountExpense: [],
  accountIncome: [],
  accountDetail: {},
  error: {},
  loading: false,
  accountCategoryDetail: {},
  accountCategory: {},
  accountCategoryLoad: false,
}

const Accounts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACCOUNTS:
    case GET_ACCOUNTS_DETAIL:
    case CREATE_ACCOUNTS:
    case UPDATE_ACCOUNTS:
      return {
        ...state,
        loading: true,
      }
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.payload,
        loading: false,
      }
    case GET_ACCOUNT_INCOME_SUCCESS:
      return {
        ...state,
        accountIncome: action.payload,
        loading: false,
      }
    case GET_ACCOUNTS_EXPENSE_SUCCESS:
      return {
        ...state,
        accountExpense: action.payload,
        loading: false,
      }
    case GET_ACCOUNTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ACCOUNTS_DETAIL_SUCCESS:
      return {
        ...state,
        accountDetail: action.payload,
        loading: false,
      }

    case GET_ACCOUNTS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accountDetail: action.payload,
        accounts: {
          ...state.accounts,
          results: [action.payload, ...state.accounts?.results],
        },
        loading: false,
      }
    case CREATE_ACCOUNTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accountDetail: action.payload,
        loading: false,
      }
    case UPDATE_ACCOUNTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          results: state.accounts?.results?.filter(
            accounts => accounts.id !== action.payload
          ),
        },

        loading: false,
      }

    case DELETE_ACCOUNTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    //account category

    case GET_ACCOUNT_CATEGORY:
    case GET_ACCOUNT_CATEGORY_DETAIL:
    case CREATE_ACCOUNT_CATEGORY:
    case UPDATE_ACCOUNT_CATEGORY:
      return {
        ...state,
        accountCategoryLoad: true,
      }
    case GET_ACCOUNT_CATEGORY_SUCCESS:
      return {
        ...state,
        accountCategory: action.payload,
        accountCategoryLoad: false,
      }

    case GET_ACCOUNT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        accountCategoryLoad: false,
      }

    case GET_ACCOUNT_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        accountCategoryDetail: action.payload,
        accountCategoryLoad: false,
      }

    case GET_ACCOUNT_CATEGORY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        accountCategoryLoad: false,
      }

    case CREATE_ACCOUNT_CATEGORY_SUCCESS:
      return {
        ...state,
        accountCategory: {
          ...state.accountCategory,
          results: [action.payload, ...state.accountCategory.results],
        },
        accountCategoryDetail: action.payload,
        accountCategoryLoad: false,
      }
    case CREATE_ACCOUNT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        accountCategoryLoad: false,
      }

    case UPDATE_ACCOUNT_CATEGORY_SUCCESS:
      return {
        ...state,
        accountCategoryDetail: action.payload,
        accountCategory: {
          ...state.accountCategory,
          results: state.accountCategory?.results?.map(i => i.id === action.payload.id ? action.payload : i),
        },
        accountCategoryLoad: false,
      }
    case UPDATE_ACCOUNT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        accountCategoryLoad: false,
      }

    case DELETE_ACCOUNT_CATEGORY_SUCCESS:
      return {
        ...state,
        accountCategory: {
          ...state.accountCategory,
          results: state.accountCategory?.results?.filter(
            accountCategory => accountCategory.id !== action.payload
          ),
        },
        accountCategoryLoad: false,
      }

    case DELETE_ACCOUNT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Accounts
