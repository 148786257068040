import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"

//actions
import {
  getAgentes,
  getCandidateDetail,
  getCompanies,
  updateCandidate,
} from "store/actions"
import MetaTag from "constants/Meta-tag"
import { debounce } from "lodash"
import PhoneInput from "react-phone-number-input"
import { getDifferenceInDays } from "components/hooks/useDateDifference"

const UpdateCandidate = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const {
    singleCandidate,
    loading,
    error,
    company,
    companyLoad,
    agentes,
    agentesLoad,
  } = useSelector(state => ({
    loading: state.Users.loading,
    error: state.Users.error,
    singleCandidate: state.Users.singleCandidate,

    company: state.Users.company,
    companyLoad: state.Users.companyLoad,

    agentes: state.Users.agentes,
    agentesLoad: state.Users.agentesLoad,
  }))

  const [is_via_agent, setIs_via_agent] = useState(false)
  const [is_malta_insurance, setIs_malta_insurance] = useState(false)
  const [is_india_insurance, setIs_india_insurance] = useState(false)

  const [dateofExpiry, setDateofExpiry] = useState({
    date: "",
    errorMessage: "",
  })

  useEffect(() => {
    var today = new Date()
    const expiryDate = new Date(dateofExpiry.date)

    var d = new Date()
    d.setMonth(d.getMonth() + 6)
    const nextSixMonthDays = Math.round(getDifferenceInDays(today, d))

    const expiryDateDiff = Math.round(getDifferenceInDays(today, expiryDate))

    if (expiryDateDiff <= nextSixMonthDays) {
      setDateofExpiry({
        ...dateofExpiry,
        errorMessage: "note: Your passport will expire soon",
      })
    } else {
      setDateofExpiry({ ...dateofExpiry, errorMessage: "" })
    }
  }, [dateofExpiry.date])

  const [phoneNumber, setPhoneNUmber] = useState() //phone

  //companySelect
  const [companySelect, setCompanySelect] = useState()
  const [companySearch, setCompanySearch] = useState()
  const [companyId, setCompanyId] = useState()

  const debounceCompanySearch = debounce(value => setCompanySearch(value), 600)

  const handleCompanyEnters = textEntered => {
    debounceCompanySearch(textEntered)
  }

  function handlerCompanyFinalValue(event) {
    const company = {
      ...companySelect,
      title: event.label,
      id: event.value,
    }
    setCompanySelect(company)
    setCompanyId(event.value)
  }
  const companyOptions = [
    {
      options: company?.results?.map((result, index) => ({
        key: index,
        label: result.company_name,
        value: result.id,
      })),
    },
  ]
  //agentSelect
  const [agentSelect, setAgentSelect] = useState()
  const [agentSearch, setAgentSearch] = useState()
  const [agentId, setAgentId] = useState()

  const debounceAgentSearch = debounce(value => setAgentSearch(value), 600)

  const handleAgentEnters = textEntered => {
    debounceAgentSearch(textEntered)
  }

  function handlerAgentFinalValue(event) {
    const agent = {
      ...companySelect,
      title: event.label,
      id: event.value,
    }
    setAgentSelect(agent)
    setAgentId(event.value)
  }
  const agentOptions = [
    {
      options: agentes?.results?.map((result, index) => ({
        key: index,
        label: result.agent_name,
        value: result.id,
      })),
    },
  ]

  function handleValidSubmit(e, values) {
    const finalData = {
      ...values,
      phone: phoneNumber,
      company: companyId,
      agent: agentId,
      date_of_expiry: values?.date_of_expiry ? values?.date_of_expiry : null,
      date_of_first_payment: values?.date_of_first_payment
        ? values?.date_of_first_payment
        : null,
      date_of_issue: values?.date_of_issue ? values?.date_of_issue : null,
      date_of_last_payment: values?.date_of_last_payment
        ? values?.date_of_last_payment
        : null,
      dob: values?.dob ? values?.dob : null,
      insurance_india_date: values?.insurance_india_date
        ? values?.insurance_india_date
        : null,
      insurance_malta_date: values?.insurance_malta_date
        ? values?.insurance_malta_date
        : null,
    }
    dispatch(updateCandidate(finalData, singleCandidate?.id, history))
  }

  useEffect(() => {
    dispatch(getCandidateDetail(params.id))
  }, [])

  useEffect(() => {
    dispatch(getCompanies(companySearch))
  }, [dispatch, companySearch])

  useEffect(() => {
    dispatch(getAgentes(agentSearch))
  }, [dispatch, agentSearch])

  useEffect(() => {
    const agent = {
      title: singleCandidate?.agent_name,
      id: singleCandidate.agent,
    }
    setAgentSelect(agent)
    setAgentId(singleCandidate?.agent)

    const company = {
      title: singleCandidate?.company_name,
      id: singleCandidate?.company,
    }
    setCompanySelect(company)
    setCompanyId(singleCandidate?.company)

    setIs_via_agent(singleCandidate?.is_via_agent)
    setIs_india_insurance(singleCandidate?.insurance_india_date)
    setIs_malta_insurance(singleCandidate?.is_malta_insurance)

    setPhoneNUmber(singleCandidate?.phone)
  }, [singleCandidate])

  return (
    <>
      <MetaTag title="Candidate" />

      <div className="page-content">
        <Breadcrumbs title="Candidates" breadcrumbItem="Update Candidate" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={12}>
                <AvForm
                  className="form-horizontal "
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  <Card className="">
                    <CardBody>
                      <CardTitle className="h4 mb-4">Fill this form</CardTitle>
                      <div className="row mb-4">
                        <Col className={`col-5 mb-4`}>
                          <AvField
                            label={"Given Name"}
                            id={`input-id-given_name`}
                            className="filePathClass"
                            name={"given_name"}
                            type={"text"}
                            validate={{
                              required: { value: false },
                              pattern: {
                                value: "[a-zA-Z]",
                                errorMessage:
                                  "Your name must be composed only with letter",
                              },
                            }}
                            value={singleCandidate?.given_name}
                          />
                        </Col>
                        <Col className={`col-5 mb-4`}>
                          <AvField
                            label={"Sure Name"}
                            id={`input-id-surname`}
                            className="filePathClass"
                            name={"surname"}
                            type={"text"}
                            validate={{
                              required: { value: false },
                              pattern: {
                                value: "[a-zA-Z]",
                                errorMessage:
                                  "Your name must be composed only with letter",
                              },
                            }}
                            value={singleCandidate?.surname}
                          />
                        </Col>
                        <Col className={`col-2 mb-4`}>
                          <AvField
                            label={"Sex"}
                            id={`input-id-sex`}
                            type={"select"}
                            name="sex"
                            validate={{
                              required: { value: false },
                            }}
                            value={singleCandidate?.sex}
                          >
                            <option>Select</option>
                            {["male", "female"]?.map((item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </AvField>
                        </Col>

                        <Col className={`col-5 mb-4`}>
                          <label>Phone</label>
                          <div className="d-flex align-items-end">
                            <PhoneInput
                              labels={{}}
                              defaultCountry="MT"
                              countryCallingCodeEditable={false}
                              placeholder="Enter phone number"
                              value={phoneNumber}
                              onChange={setPhoneNUmber}
                              international
                              withCountryCallingCode={true}
                            />
                          </div>
                        </Col>

                        <Col className={`col-3 mb-4`}>
                          <AvField
                            label={"Email"}
                            id={`input-id-email`}
                            className="filePathClass"
                            name={"email"}
                            type={"email"}
                            validate={{
                              required: { value: false },
                              pattern: {
                                value: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                                errorMessage: "Please Enter Valid Email Id",
                              },
                            }}
                            value={singleCandidate?.email}
                          />
                        </Col>

                        {/* <Col
                          className={`col-4 mb-4`}
                        >
                          <AvField
                            label={"Name of father"}
                            id={`input-id-name_of_father`}
                            className="filePathClass"
                            name={"name_of_father"}
                            type={"text"}
                            validate={{
                              required: { value: false },
                              pattern: { value: "[a-zA-Z]", errorMessage: "Name of father must be composed only with letter" },
                            }}
                            value={singleCandidate?.name_of_father}

                          />
                        </Col> */}

                        {/* <Col
                          className={`col-4 mb-4`}
                        >
                          <AvField
                            label={"Name of mother"}
                            id={`input-id-name_of_mother`}
                            className="filePathClass"
                            name={"name_of_mother"}
                            type={"text"}
                            validate={{
                              required: { value: false },
                              pattern: { value: "[a-zA-Z]", errorMessage: "Name of mother must be composed only with letter" },
                            }}
                            value={singleCandidate?.name_of_mother}

                          />
                        </Col> */}

                        {/* <Col
                          className={`col-4 mb-4`}
                        >
                          <AvField
                            label={"Name of spouse"}
                            id={`input-id-name_of_spouse`}
                            className="filePathClass"
                            name={"name_of_spouse"}
                            type={"text"}
                            validate={{
                              required: { value: false },
                              pattern: { value: "[a-zA-Z]", errorMessage: "Name of spouse must be composed only with letter" },
                            }}
                            value={singleCandidate?.name_of_spouse}

                          />
                        </Col> */}

                        <Col className={`col-2 mb-2`}>
                          <AvField
                            label={"Date of birth"}
                            id={`input-id-dob`}
                            className="filePathClass"
                            name={"dob"}
                            type={"date"}
                            validate={{
                              date: { format: "YYYY-MM-DD" },
                              required: { value: false },
                            }}
                            value={singleCandidate?.dob}
                          />
                        </Col>
                        <Col className={`col-2 mb-4`}>
                          <AvField
                            label={"Place of birth"}
                            id={`input-id-place_of_birth`}
                            className="filePathClass"
                            name={"place_of_birth"}
                            type={"text"}
                            validate={{
                              required: { value: false },
                              pattern: {
                                value: "[a-zA-Z]",
                                errorMessage:
                                  "Place must be composed only with letter",
                              },
                            }}
                            value={singleCandidate?.place_of_birth}
                          />
                        </Col>

                        <Col className={`col-5 mb-4`}>
                          <AvField
                            label={"Passport No *"}
                            id={`input-id-passport_no`}
                            className="filePathClass"
                            name={"passport_no"}
                            type={"text"}
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^(?!^0+$)[a-zA-Z0-9]{3,20}$",
                                errorMessage: "Enter a valid Passport Number",
                              },
                            }}
                            value={singleCandidate?.passport_no}
                          />
                        </Col>

                        <Col className={`col-3 mb-4`}>
                          <AvField
                            label={"Place of issue"}
                            id={`input-id-place_of_issue`}
                            className="filePathClass"
                            name={"place_of_issue"}
                            type={"text"}
                            validate={{
                              required: { value: false },
                              pattern: {
                                value: "[a-zA-Z]",
                                errorMessage:
                                  "Place must be composed only with letter",
                              },
                            }}
                            value={singleCandidate?.place_of_issue}
                          />
                        </Col>

                        <Col className={`col-2 mb-2`}>
                          <AvField
                            label={"Date of issue"}
                            id={`input-id-date_of_issue`}
                            className="filePathClass"
                            name={"date_of_issue"}
                            type={"date"}
                            validate={{
                              date: { format: "YYYY-MM-DD" },
                              required: { value: false },
                            }}
                            value={singleCandidate?.date_of_issue}
                          />
                        </Col>

                        <Col className={`col-2 mb-2`}>
                          <AvField
                            label={"Date of expiry"}
                            id={`input-id-date_of_expiry`}
                            className="filePathClass"
                            name={"date_of_expiry"}
                            type={"date"}
                            validate={{
                              date: { format: "YYYY-MM-DD" },
                              required: { value: false },
                              pattern: {
                                value: dateofExpiry.errorMessage
                                  ? "[a-zA-Z]"
                                  : "",
                                errorMessage: dateofExpiry.errorMessage,
                              },
                            }}
                            value={singleCandidate?.date_of_expiry}
                            onChange={e => setDateofExpiry(e.target.value)}
                          />
                        </Col>

                        <Col className={`col-5 mb-4`}>
                          <AvField
                            label={"Address"}
                            id={`input-id-address`}
                            className="filePathClass"
                            name={"address"}
                            type={"textarea"}
                            validate={{
                              required: { value: false },
                              pattern: {
                                value: "^[A-Za-z0-9]+$",
                                errorMessage:
                                  "Your address must be composed only with letter and numbers",
                              },
                            }}
                            value={singleCandidate?.address}
                          />
                        </Col>

                        <Col className={`col-5 mb-4`}>
                          <AvField
                            label={"Note (optional)"}
                            id={`input-id-note`}
                            className="filePathClass"
                            name={"note"}
                            type={"textarea"}
                            validate={{
                              required: { value: false },
                              pattern: {
                                value: "^[A-Za-z0-9]",
                                errorMessage:
                                  "Your address must be composed only with letter and numbers",
                              },
                            }}
                          />
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="">
                    <CardBody>
                      <div className="row mb-4">
                        <Col className={`col-4 mb-4`}>
                          <FormGroup className="w-100 custom_store_select">
                            <div className="ajax-select mt-lg-0 select2-container new_custom_store_select">
                              <p className="text-muted mb-2 p-0">
                                select a Company
                              </p>
                              <Select
                                onInputChange={handleCompanyEnters}
                                value={companySelect?.title}
                                placeholder={companySelect?.title}
                                onChange={handlerCompanyFinalValue}
                                options={companyOptions}
                                classNamePrefix="select2-selection"
                                isLoading={companyLoad}
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col className={`col-4 mb-4`}>
                          <AvField
                            label={"Job title"}
                            id={`input-id-job_title`}
                            className="filePathClass"
                            name={"job_title"}
                            type={"text"}
                            validate={{
                              required: { value: false },
                              pattern: {
                                value: "[a-zA-Z]",
                                errorMessage:
                                  "Job title must be composed only with letter",
                              },
                            }}
                            value={singleCandidate?.job_title}
                          />
                        </Col>

                        {/* <Col
                          className={`col-4 mb-4`}
                        >
                          <AvField
                            label={"Position"}
                            id={`input-id-position`}
                            className="filePathClass"
                            name={"position"}
                            type={"text"}
                            validate={{
                              required: { value: false },
                              pattern: { value: "[a-zA-Z]", errorMessage: "Position must be composed only with letter" },
                            }}
                            value={singleCandidate?.position}

                          />
                        </Col> */}

                        <Col className={`col-4 mb-4`}>
                          <AvField
                            label={"Estimated Amount"}
                            id={`input-id-estimated_amount`}
                            className="filePathClass"
                            name={"estimated_amount"}
                            type={"number"}
                            validate={{
                              required: { value: false },
                            }}
                            value={singleCandidate?.estimated_amount}
                          />
                        </Col>

                        <Col className={`col-2 mb-2`}>
                          <AvField
                            label={"Date of First Payment"}
                            id={`input-id-date_of_first_payment`}
                            className="filePathClass"
                            name={"date_of_first_payment"}
                            type={"date"}
                            validate={{
                              date: { format: "YYYY-MM-DD" },
                              required: { value: false },
                            }}
                            value={singleCandidate?.date_of_first_payment}
                          />
                        </Col>

                        <Col className={`col-2 mb-2`}>
                          <AvField
                            label={"First Payment Amount"}
                            id={`input-id-first-payment-amount`}
                            className="filePathClass"
                            name={"first_payment_amount"}
                            type={"number"}
                            validate={{
                              required: { value: false },
                            }}
                            value={singleCandidate?.first_payment_amount}
                          />
                        </Col>

                        <Col className={`col-2 mb-2`}>
                          <AvField
                            label={"Date of Last Payment"}
                            id={`input-id-date_of_last_payment`}
                            className="filePathClass"
                            name={"date_of_last_payment"}
                            type={"date"}
                            validate={{
                              date: { format: "YYYY-MM-DD" },
                              required: { value: false },
                            }}
                            value={singleCandidate?.date_of_last_payment}
                          />
                        </Col>

                        <Col className={`col-2 mb-2`}>
                          <AvField
                            label={"Last Payment Amount"}
                            id={`input-id-last-payment-amount`}
                            className="filePathClass"
                            name={"last_payment_amount"}
                            type={"number"}
                            validate={{
                              required: { value: false },
                            }}
                            value={singleCandidate?.last_payment_amount}
                          />
                        </Col>
                      </div>
                      <Row className="row">
                        <Col
                          className={`col-3 mb-0`}
                          style={{ paddingTop: is_via_agent && "10px" }}
                        >
                          <AvField
                            label="Via agent"
                            id={`input-id-is_via_agent`}
                            className="filePathClass"
                            name="is_via_agent"
                            type="checkbox"
                            value={singleCandidate?.is_via_agent}
                            onChange={e => setIs_via_agent(e.target.checked)}
                          />
                        </Col>
                        {is_via_agent && (
                          <Col className={`col-6 mb-4`}>
                            <FormGroup className="w-100 custom_store_select">
                              <div className="ajax-select mt-lg-0 select2-container new_custom_store_select d-flex w-100 align-items-center">
                                <p
                                  className="text-muted m-0 mx-2 p-0 "
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  Select a agent :
                                </p>
                                <Select
                                  onInputChange={handleAgentEnters}
                                  value={agentSelect?.title}
                                  placeholder={agentSelect?.title}
                                  onChange={handlerAgentFinalValue}
                                  options={agentOptions}
                                  classNamePrefix="select2-selection custom_select"
                                  isLoading={agentesLoad}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row className="row">
                        <Col
                          className={`col-3 `}
                          style={{ paddingTop: is_malta_insurance && "10px" }}
                        >
                          <AvField
                            label="Malta insurance"
                            id={`input-id-is_malta_insurance`}
                            className="filePathClass"
                            name="is_malta_insurance"
                            type="checkbox"
                            value={singleCandidate?.is_malta_insurance}
                            onChange={e =>
                              setIs_malta_insurance(e.target.checked)
                            }
                          />
                        </Col>
                        {is_malta_insurance && (
                          <Col
                            className={`col-6 mb-4 d-flex align-items-center`}
                          >
                            <p
                              className="text-muted m-0 mx-2 p-0 "
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Insurance malta date :
                            </p>
                            <AvField
                              id={`input-id-insurance_malta_date`}
                              className="filePathClass w-100"
                              name="insurance_malta_date"
                              type="date"
                              validate={{
                                date: { format: "YYYY-MM-DD" },
                              }}
                              value={singleCandidate?.insurance_malta_date}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className="row">
                        <Col
                          className={`col-3`}
                          style={{ paddingTop: is_india_insurance && "10px" }}
                        >
                          <AvField
                            label="India insurance"
                            id={`input-id-is_india_insurance`}
                            className="filePathClass"
                            name="is_india_insurance"
                            type="checkbox"
                            onChange={e =>
                              setIs_india_insurance(e.target.checked)
                            }
                            value={singleCandidate?.is_india_insurance}
                          />
                        </Col>
                        {is_india_insurance && (
                          <Col
                            className={`col-6 mb-4 d-flex align-items-center`}
                          >
                            <p
                              className="text-muted m-0 mx-2 p-0 "
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Insurance malta date :
                            </p>
                            <AvField
                              id={`input-id-insurance_india_date`}
                              className="filePathClass w-100"
                              name="insurance_india_date"
                              type="date"
                              validate={{
                                date: { format: "YYYY-MM-DD" },
                              }}
                              value={singleCandidate?.insurance_india_date}
                            />
                          </Col>
                        )}
                      </Row>
                      <div className="row">
                        <Col className={`col-12 mb-0`}>
                          <AvField
                            label={"Dummy ticket"}
                            id={`input-id-is_dummy_ticket`}
                            className="filePathClass"
                            name={"is_dummy_ticket"}
                            type={"checkbox"}
                            validate={{
                              required: { value: false },
                            }}
                            value={singleCandidate?.is_dummy_ticket}
                          />
                        </Col>
                        <Col className={`col-12 mb-0`}>
                          <AvField
                            label={"Medical"}
                            id={`input-id-is_medical`}
                            className="filePathClass"
                            name={"is_medical"}
                            type={"checkbox"}
                            validate={{
                              required: { value: false },
                            }}
                            value={singleCandidate?.is_medical}
                          />
                        </Col>
                        <Col className={`col-12 mb-0`}>
                          <AvField
                            label={"Accomadation"}
                            id={`input-id-is_accomadation`}
                            className="filePathClass"
                            name={"is_accomadation"}
                            type={"checkbox"}
                            validate={{
                              required: { value: false },
                            }}
                            value={singleCandidate?.is_accomadation}
                          />
                        </Col>
                      </div>

                      <div className="row justify-content-end">
                        <Col sm={12}>
                          <div className="d-flex justify-content-end me-2">
                            <Button
                              type="submit"
                              color="success"
                              className="w-25"
                              style={{ fontSize: "14px", borderRadius: "10px" }}
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-20 align-end me-2"></i>
                                </>
                              )}
                              Update
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                </AvForm>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateCandidate

UpdateCandidate.propTypes = {
  history: PropTypes.object,
}
