import MetaTag from "constants/Meta-tag"
import React from "react"
import { Container } from "reactstrap"
import DatatableTables from "./DatatableTables"
import Breadcrumbs from "components/Common/Breadcrumb"

function Candidates() {
  return (
    <>
      <MetaTag title={"All Candidates"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Candidates" />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Candidates
