import React, { useState } from "react"
import { CardTitle, Col, Container, Row } from "reactstrap"
import { useEffect } from "react"
import { Card, CardBody, Media } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"
import user from "assets/images/logo/user.png"

//actions
import {
  deleteCandidate,
  getCandidateDetail,
  getCandidatePiachart,
  uploadCandidatePic,
} from "store/actions"

//componetns
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import MetaTag from "constants/Meta-tag"
import PieChart from "pages/Dashboard/charts/pieChart"
import Account from "pages/account/DatatableTables"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ImageUpload from "./imageUpload"
import moment from "moment"
import DocumentUpload from "./DocumentUpload"

const CandidateDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { singleCandidate, loading, chartLoad, candidateChart, candidates } =
    useSelector(state => ({
      loading: state.Users.candidatesLoad,
      singleCandidate: state.Users.singleCandidate,
      chartLoad: state.Users.chartLoad,
      candidateChart: state.Users.candidateChart,
      candidates: state.Users.singleAgente.workers,
    }))

  const [candidateStatus, setCandidateStatus] = useState("")
  const [handleImageModal, setHandleImageModal] = useState(false)

  useEffect(() => {
    dispatch(getCandidateDetail(params.id))
  }, [dispatch, params])

  useEffect(() => {
    if (params?.id) {
      dispatch(getCandidatePiachart(params?.id))
    }
  }, [dispatch, params])

  const onDeleteFun = itemId => {
    dispatch(deleteCandidate(itemId, history))
  }

  useEffect(() => {
    const finalData = {
      status: candidateStatus,
    }
    if (candidateStatus && params?.id) {
      dispatch(uploadCandidatePic(finalData, params?.id))
    }
  }, [candidateStatus])

  const status = [
    { lable: "New", value: "new" },
    { lable: "Fund received", value: "fund_received" },
    { lable: "Applied in embassy", value: "applied_in_embassy" },
    { lable: "Processing", value: "processing" },
    { lable: "Applied in india", value: "applied_in_india" },
    { lable: "Visa approved", value: "visa_approved" },
    { lable: "Rejected", value: "rejected" },
  ]

  const handleStatusText = status => {
    switch (status) {
      case "new":
        return "New"
      case "fund_received":
        return "Fund received"
      case "applied_in_embassy":
        return "Applied in embassy"
      case "processing":
        return "Processing"
      case "applied_in_india":
        return "Applied in india"
      case "visa_approved":
        return "Visa approved"
      case "rejected":
        return "Rejected"
      default:
        return
    }
  }

  candidateChart.profit = singleCandidate?.total_income
    ? singleCandidate?.total_income
    : 0

  return (
    <>
      <ImageUpload
        isOpen={handleImageModal}
        onClose={setHandleImageModal}
        candidateId={params?.id}
      />
      <MetaTag title="Candidate" />

      <div className="page-content">
        <Breadcrumbs title="Candidate" breadcrumbItem="Candidates" />
        <Container fluid className="">
          <div className="container-fluid">
            <Row>
              <Col xl="12" className="sm:p-0">
                <Card>
                  <CardBody>
                    <Row>
                      <Col
                        lg="6"
                        md="6"
                        className="mb-4 d-flex flex-column align-items-start justify-content-start"
                      >
                        <Media className="align-self-center d-flex w-100" body>
                          <div className="image_wrapper">
                            {singleCandidate?.image ? (
                              <img
                                src={singleCandidate?.image}
                                alt="user"
                                height={"120px"}
                                width={"120px"}
                              />
                            ) : (
                              <img
                                src={user}
                                alt="user"
                                height={"120px"}
                                width={"120px"}
                                className="defualt_images"
                              />
                            )}
                            <div
                              className="shadow"
                              onClick={() => setHandleImageModal(true)}
                            ></div>
                            <p
                              className="text-center mb-0 edit_image_btn"
                              style={{ cursor: "pointer" }}
                              onClick={() => setHandleImageModal(true)}
                            >
                              Edit
                            </p>
                            <i
                              title="Upload Candidate Profile Pic"
                              className="bx bx-pen mx-1"
                              onClick={() => setHandleImageModal(true)}
                              style={{
                                fontSize: "16px",
                                cursor: "pointer",
                                position: "absolute",
                                bottom: "0px",
                                right: "-5px",
                                background: "#fff",
                                borderRadius: "5px",
                                padding: "2px",
                                color: "green",
                              }}
                            />
                          </div>
                          <div className="p-3 pt-0 px-4">
                            <h5 className="mb-0">
                              {singleCandidate?.given_name}{" "}
                            </h5>
                            <p>
                              join on{" "}
                              {moment(singleCandidate?.date_added).format(
                                "YYYY-MM-DD"
                              )}
                            </p>
                            <p className="mb-0">{singleCandidate?.email}</p>
                            <p className="mb-0">{singleCandidate?.phone}</p>
                            <p className="mb-0">
                              {singleCandidate?.company_name}
                            </p>
                            <p>{singleCandidate?.job_title}</p>
                          </div>
                        </Media>
                        <div className="d-flex ">
                          <AvForm>
                            <AvField
                              label={""}
                              id={`input-id-select`}
                              type={"select"}
                              name="type"
                              onChange={e => setCandidateStatus(e.target.value)}
                            >
                              <option>
                                {handleStatusText(singleCandidate?.status)}
                              </option>
                              {status?.map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.lable}
                                </option>
                              ))}
                            </AvField>
                          </AvForm>

                          <Link
                            className="d-flex align-items-center mx-3 btn btn-light"
                            to={`/renew/candidate/${singleCandidate?.id}`}
                          >
                            <i
                              title="Update candidate info"
                              className="bx bx-pen mx-1"
                              style={{ fontSize: "16px", cursor: "pointer" }}
                            />
                            Edit Profile
                          </Link>

                          <div className="d-flex align-items-center">
                            <i
                              onClick={() => {
                                onDeleteFun(singleCandidate?.id)
                              }}
                              title="Delete candidate"
                              className="bx bx-trash mx-1"
                              style={{ fontSize: "16px", cursor: "pointer" }}
                            />
                          </div>
                        </div>

                        <DocumentUpload />
                      </Col>

                      <Col lg="6" md="6" className="d-lg-block">
                        <div className="d-flex justify-content-evenly">
                          <div className="align-self-center text-center mb-4">
                            <PieChart
                              chartData={candidateChart}
                              loading={chartLoad}
                            />
                            <h6>Expencess</h6>
                          </div>
                          <div className="align-self-center h-240">
                            <div className="mb-2">
                              <h6
                                className="mb-1"
                                style={{ fontWeight: "400", fontSize: "14px" }}
                              >
                                Agreed amount
                              </h6>
                              <p
                                className="mb-1 text-muted"
                                style={{ fontSize: "16px", fontWeight: "500" }}
                              >
                                {singleCandidate?.estimated_amount}{" "}
                                <span className="font-size-12"> /-</span>
                              </p>
                            </div>
                            <div className="mb-2">
                              <h6
                                className="mb-1"
                                style={{ fontWeight: "400", fontSize: "14px" }}
                              >
                                Received amount
                              </h6>
                              <p
                                className="mb-1 text-muted"
                                style={{ fontSize: "16px", fontWeight: "500" }}
                              >
                                {singleCandidate?.total_income}{" "}
                                <span className="font-size-12"> /-</span>
                              </p>
                            </div>
                            <div className="text-black mb-2">
                              <h5
                                className="mb-1"
                                style={{ fontWeight: "600" }}
                              >
                                Amount to receive
                              </h5>
                              <h5 className="mb-1 text-green">
                                {singleCandidate?.estimated_amount -
                                  singleCandidate?.total_income}
                                {/* {singleCandidate?.balance_amount}{" "} */}

                                <span className="font-size-12"> /-</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="6" md="6" className="mb-4">
                        <CardTitle>Details</CardTitle>
                        <Media className="align-self-center" body>
                          <h6 className="mb-1">
                            {singleCandidate?.surname} s/o{" "}
                            {singleCandidate?.name_of_father}
                          </h6>
                          {singleCandidate?.dob && (
                            <p className="mb-1">
                              Date of birth : {singleCandidate?.dob}
                            </p>
                          )}
                          {singleCandidate?.place_of_birth && (
                            <p className="mb-1">
                              Place of birth : {singleCandidate?.place_of_birth}
                            </p>
                          )}
                          <p></p>
                          {singleCandidate?.address && (
                            <p className="mb-1">
                              Address : {singleCandidate?.address}
                            </p>
                          )}
                          {/* {singleCandidate?.name_of_mother && (
                            <p className="mb-1">
                              Name of mother : {singleCandidate?.name_of_mother}
                            </p>
                          )} */}
                          {singleCandidate?.name_of_spouse && (
                            <p className="mb-1">
                              Name of spouse : {singleCandidate?.name_of_spouse}
                            </p>
                          )}
                        </Media>
                      </Col>

                      <Col lg="6" md="6">
                        <Media className="align-self-center mb-4" body>
                          {singleCandidate?.passport_no && (
                            <p className="mb-1">
                              Passport : {singleCandidate?.passport_no}
                            </p>
                          )}
                          {singleCandidate?.nationality && (
                            <p className="mb-1">
                              Nationality : {singleCandidate?.nationality}
                            </p>
                          )}
                          {singleCandidate?.sex && (
                            <p className="mb-1">Sex : {singleCandidate?.sex}</p>
                          )}
                          {singleCandidate?.place_of_issue && (
                            <p className="mb-1">
                              Place of issue : {singleCandidate?.place_of_issue}
                            </p>
                          )}
                          {singleCandidate?.date_of_issue && (
                            <p className="mb-1">
                              Date of issue : {singleCandidate?.date_of_issue}
                            </p>
                          )}
                          {singleCandidate?.date_of_expiry && (
                            <p className="mb-1">
                              Date of Expiry : {singleCandidate?.date_of_expiry}
                            </p>
                          )}

                          <p></p>

                          {singleCandidate?.company && (
                            <p className="mb-1">
                              Company : {singleCandidate?.company_name}
                            </p>
                          )}
                          {singleCandidate?.job_title && (
                            <p className="mb-1">
                              Job title : {singleCandidate?.job_title}
                            </p>
                          )}
                          {singleCandidate?.position && (
                            <p className="mb-1">
                              Position : {singleCandidate?.position}
                            </p>
                          )}
                        </Media>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Account candidateId={params?.id} />
          </div>
        </Container>
      </div>
    </>
  )
}

export default CandidateDetails

CandidateDetails.propTypes = {
  history: PropTypes.object,
}
