import PropTypes from "prop-types"
import { Button, Modal, ModalBody } from "reactstrap"
import React, { useState } from "react"
import Dropzone from "react-dropzone"
import { useDispatch, useSelector } from "react-redux"
import {
  updateCandidateDocuments,
  uploadCandidateDocuments,
} from "store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { RxCross2 } from "react-icons/rx"
import docs from "../../../assets/images/logo/doc.jpg"

const DocumentModal = ({ onCloseClick, show, data }) => {
  const dispatch = useDispatch()
  const { singleCandidate } = useSelector(state => ({
    singleCandidate: state.Users.singleCandidate,
  }))

  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    setImage({
      image: files,
    })

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [image, setImage] = React.useState({
    image: null,
  })

  const handleValidSubmit = (e, v) => {
    const finalData = {
      document_name: v?.document_name,
      worker: singleCandidate?.id,
      documentId: data?.id,
    }
    if (data?.id) {
      dispatch(updateCandidateDocuments(finalData, image, onCloseClick))
    } else if (image?.image !== null) {
      dispatch(uploadCandidateDocuments(finalData, image, onCloseClick))
    }
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
      className="delete-modal"
    >
      <ModalBody style={deleteModalBodyStyle} className="p-0">
        <div className="d-flex align-items-start p-4 flex-column  w-100 position-relative">
          <div className="top-icons m-1" onClick={onCloseClick}>
            <RxCross2 className="delete-icon" size={"1.2rem"} />
          </div>
          <div className="d-flex">
            <Dropzone
              onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="">
                  <div className="needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div>
                      <p className="m-0 p-0 pb-2">Select File</p>

                      <div
                        className="mb-3 m-0 file-box  rounded image-i-size imageUpload"
                        style={{
                          width: 100,
                          height: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px dashed #d4d2d2",
                        }}
                      >
                        <i className="bx bx-image-add image-i-color bx-burst-hover" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>{" "}
            {selectedFiles[0]?.preview ? (
              <>
                {selectedFiles[0]?.type === "image/jpeg" ||
                selectedFiles[0]?.type === "image/png" ? (
                  <>
                    {selectedFiles[0]?.preview && (
                      <div className="">
                        <p className="m-0 p-0 pb-2">&nbsp;</p>

                        {selectedFiles.map((f, i) => (
                          <img
                            key={i}
                            className="dotted mx-2 rounded"
                            style={{ height: "100px", width: "100px" }}
                            alt={f.name}
                            src={f.preview}
                          />
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="">
                      <p className="m-0 p-0 pb-2">&nbsp;</p>
                      <img
                        className="dotted mx-2 rounded"
                        style={{ height: "100px", width: "100px" }}
                        alt={selectedFiles[0]?.name}
                        src={docs}
                      />
                      <p className="m-0 p-0 pb-2 mx-2 pt-1">
                        {selectedFiles[0]?.name}
                      </p>
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="w-100">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              <AvField
                label={"Document Name"}
                id={`input-id-document_name`}
                className="filePathClass w-100"
                name={"document_name"}
                type={"textarea"}
                style={{ height: 35 }}
                value={data?.document_name ? data?.document_name : ""}
              />
              <Button
                type="submit"
                color="success"
                className="w-100 mt-2 d-flex align-items-cetner justify-content-center"
                style={{ fontSize: "14px", borderRadius: "10px" }}
              >
                Upload
              </Button>
            </AvForm>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

DocumentModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.bool,
  modal: PropTypes.object,
}

export default DocumentModal

const deleteModalStyle = {
  width: 300,
}
const deleteModalBodyStyle = {
  minHeight: 300,
}
