import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"

//actions
import {
  createAccount,
  getAccountCategory,
  getCandidates,
  hideAccountPopup,
} from "store/actions"

import { debounce } from "lodash"
import moment from "moment"
const CreateAccount = ({ history, candidateId }) => {
  const dispatch = useDispatch()

  const {
    loading,
    accountCategory,
    accountCategoryLoad,
    candidates,
    candidatesLoad,
    isAccountPopup,
    accountIncome,
    accountExpense,
  } = useSelector(state => ({
    candidates: state.Users.candidates,
    candidatesLoad: state.Users.candidatesLoad,
    accountCategory: state.Accounts.accountCategory,
    accountCategoryLoad: state.Accounts.accountCategoryLoad,
    loading: state.Accounts.loading,
    isAccountPopup: state.Layout.isAccountPopup,
    accountIncome: state.Accounts.accountIncome,
    accountExpense: state.Accounts.accountExpense,
  }))

  const [transaction_type, setTransaction_type] = useState("credit")
  const [sort, setSort] = useState("income")

  //account_category
  const [accCatSelect, setAccCatSelect] = useState()
  const [accCatSearch, setAccCatSearch] = useState()
  const [accCatId, setAccCatId] = useState()

  const debounceAccCatSearch = debounce(value => setAccCatSearch(value), 600)

  const handleAccCatEnters = textEntered => {
    debounceAccCatSearch(textEntered)
  }

  function handlerAccCatFinalValue(event) {
    const accCat = {
      ...accCatSelect,
      title: event.label,
      id: event.value,
    }
    setAccCatSelect(accCat)
    setAccCatId(event.value)
  }

  const handleCat = () => {
    if (sort === "credit") {
      return accountIncome
    } else if (sort === "debit") {
      return accountExpense
    } else return accountCategory
  }

  const accCatOptions = [
    {
      options: handleCat()?.results?.map((result, index) => ({
        key: index,
        label: result.category_name,
        value: result.id,
      })),
    },
  ]

  //worker is candidate
  const [workerSelect, setWorkerSelect] = useState()
  const [workerSearch, setWorkerSearch] = useState()
  const [workerId, setWorkerId] = useState()

  const debounceWorkerSearch = debounce(value => setWorkerSearch(value), 600)

  const handleWorkerEnters = textEntered => {
    debounceWorkerSearch(textEntered)
  }

  function handlerWorkerFinalValue(event) {
    const worker = {
      ...workerSelect,
      title: event.label,
      id: event.value,
    }
    setWorkerSelect(worker)
    setWorkerId(event.value)
  }

  const workerOptions = [
    {
      options: candidates?.results?.map((result, index) => ({
        key: index,
        label: result?.given_name,
        value: result.id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getAccountCategory(accCatSearch, "", sort))
  }, [dispatch, accCatSearch, sort])

  useEffect(() => {
    dispatch(getCandidates(workerSearch))
  }, [dispatch, workerSearch])

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    const finalData = {
      ...values,
      account_category: accCatId,
      transaction_type: transaction_type,
      worker: candidateId ? candidateId : workerId,
    }
    dispatch(createAccount(finalData))
  }

  const onCloseClick = () => {
    dispatch(hideAccountPopup())
  }

  const handleSort = type => {
    if (type === "credit") {
      setSort("income")
      setTransaction_type("credit")
    } else {
      setSort("expense")
      setTransaction_type("debit")
    }
  }


  return (
    <>
      <Modal isOpen={isAccountPopup} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-5 px-5 position-relative">
          <i
            className="mdi mdi-close font-size-20 closeIcon"
            onClick={onCloseClick}
          ></i>
          <Row>
            <Col>
              <AvForm
                className="form-horizontal "
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-username-Input"
                    className="col-sm-4 col-form-label"
                  ></Label>
                  <Col sm={8}>
                    <div className="btn-group btn-group-example" role="group">
                      <button
                        type="button"
                        className={`btn btn-outline-success w-sm ${transaction_type === "credit"
                          ? "bg-success text-white"
                          : ""
                          } `}
                        onClick={() => handleSort("credit")}
                      >
                        Credit
                      </button>
                      <button
                        type="button"
                        onClick={() => handleSort("debit")}
                        className={`btn btn-outline-danger w-sm ${transaction_type === "debit"
                          ? "bg-danger text-white"
                          : ""
                          } `}
                      >
                        Debit
                      </button>
                    </div>
                  </Col>
                </div>
                {/* {candidateId ? (
                  <></>
                ) : (
                  <div className="row mb-4">
                    <Label
                      htmlFor="horizontal-username-Input"
                      className="col-sm-4 col-form-label"
                    >
                      Type
                    </Label>
                    <Col sm={8}>
                      <AvField name="type" type="select" className="text-muted">
                        <option>Select...</option>
                        {Types?.map((item, key) => (
                          <option key={key} value={item}>
                            {item}
                          </option>
                        ))}
                      </AvField>
                    </Col>
                  </div>
                )} */}
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-date-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Date
                  </Label>
                  <Col sm={8}>
                    <AvField
                      id={`input-id-date`}
                      className="filePathClass"
                      name={"date"}
                      type={"date"}
                      validate={{
                        date: { format: "YYYY-MM-DD" },
                        required: { value: false },
                      }}
                      value={moment(Date.now()).format('YYYY-MM-DD')}
                    />
                  </Col>
                </div>

                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-username-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Account category
                  </Label>
                  <Col sm={8}>
                    <FormGroup className="w-100 custom_store_select">
                      <div className="ajax-select mt-lg-0 select2-container new_custom_store_select">
                        <Select
                          onInputChange={handleAccCatEnters}
                          value={accCatSelect?.title}
                          placeholder={accCatSelect?.title}
                          onChange={handlerAccCatFinalValue}
                          options={accCatOptions}
                          classNamePrefix="select2-selection"
                          isLoading={accountCategoryLoad}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </div>

                {candidateId ? (
                  <></>
                ) : (
                  <div className="row mb-4">
                    <Label
                      htmlFor="horizontal-email-Input"
                      className="col-sm-4 col-form-label"
                    >
                      Candidate
                    </Label>
                    <Col sm={8}>
                      <FormGroup className="w-100 custom_store_select">
                        <div className="ajax-select mt-lg-0 select2-container new_custom_store_select">
                          <Select
                            onInputChange={handleWorkerEnters}
                            value={workerSelect?.title}
                            placeholder={workerSelect?.title}
                            onChange={handlerWorkerFinalValue}
                            options={workerOptions}
                            classNamePrefix="select2-selection"
                            isLoading={candidatesLoad}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </div>
                )}
                {/* <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-username-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Company
                  </Label>
                  <Col sm={8}>
                    <FormGroup className="w-100 custom_store_select">
                      <div className="ajax-select mt-lg-0 select2-container new_custom_store_select">
                        <Select
                          onInputChange={handleCompanyEnters}
                          value={companySelect?.title}
                          placeholder={companySelect?.title}
                          onChange={handlerCompanyFinalValue}
                          options={companyOptions}
                          classNamePrefix="select2-selection"
                          isLoading={companyLoad}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </div> */}
                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-username-Input"
                    className="col-sm-4 col-form-label"
                  >
                    Amount
                  </Label>
                  <Col sm={8}>
                    <AvField
                      id="horizontal-username-Input"
                      className="filePathClass"
                      name="amount"
                      type="number"
                    />
                  </Col>
                </div>

                <div className="row mb-4">
                  <Label
                    htmlFor="horizontal-email-Input"
                    className="col-sm-4 col-form-label"
                  >
                    description
                  </Label>
                  <Col sm={8}>
                    <AvField
                      id="horizontal-email-Input"
                      name="description"
                      className="form-control"
                      type="textarea"
                    />
                  </Col>
                </div>

                <div className="row justify-content-end">
                  <Col sm={8}>
                    <div>
                      <Button type="submit" color="success" className="w-md">
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        Submit
                      </Button>
                    </div>
                  </Col>
                </div>
              </AvForm>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CreateAccount

CreateAccount.propTypes = {
  history: PropTypes.object,
  candidateId: PropTypes.any,
}
