/* CANDIDATES */
export const GET_CANDIDATES = "GET_CANDIDATES"
export const GET_CANDIDATES_SUCCESS = "GET_CANDIDATES_SUCCESS"
export const GET_CANDIDATES_FAIL = "GET_CANDIDATES_FAIL"

export const GET_CANDIDATE_PIACHART = "GET_CANDIDATE_PIACHART"
export const GET_CANDIDATE_PIACHART_SUCCESS = "GET_CANDIDATE_PIACHART_SUCCESS"
export const GET_CANDIDATE_PIACHART_FAIL = "GET_CANDIDATE_PIACHART_FAIL"

export const GET_CANDIDATE_DETAIL = "GET_CANDIDATE_DETAIL"
export const GET_CANDIDATE_DETAIL_SUCCESS = "GET_CANDIDATE_DETAIL_SUCCESS"
export const GET_CANDIDATE_DETAIL_FAIL = "GET_CANDIDATE_DETAIL_FAIL"

export const CREATE_CANDIDATE = "CREATE_CANDIDATE"
export const CREATE_CANDIDATE_SUCCESS = "CREATE_CANDIDATE_SUCCESS"
export const CREATE_CANDIDATE_FAIL = "CREATE_CANDIDATE_FAIL"

export const UPDATE_CANDIDATE = "UPDATE_CANDIDATE"
export const UPDATE_CANDIDATE_SUCCESS = "UPDATE_CANDIDATE_SUCCESS"
export const UPDATE_CANDIDATE_FAIL = "UPDATE_CANDIDATE_FAIL"

export const UPLOAD_CANDIDATE_PIC = "UPLOAD_CANDIDATE_PIC"
export const UPLOAD_CANDIDATE_PIC_SUCCESS = "UPLOAD_CANDIDATE_PIC_SUCCESS"
export const UPLOAD_CANDIDATE_PIC_FAIL = "UPLOAD_CANDIDATE_PIC_FAIL"

export const UPLOAD_CANDIDATE_DOCUMENT = "UPLOAD_CANDIDATE_DOCUMENT"
export const UPLOAD_CANDIDATE_DOCUMENT_SUCCESS =
  "UPLOAD_CANDIDATE_DOCUMENT_SUCCESS"
export const UPLOAD_CANDIDATE_DOCUMENT_FAIL = "UPLOAD_CANDIDATE_DOCUMENT_FAIL"

export const UPDATE_CANDIDATE_DOCUMENT = "UPDATE_CANDIDATE_DOCUMENT"
export const UPDATE_CANDIDATE_DOCUMENT_SUCCESS =
  "UPDATE_CANDIDATE_DOCUMENT_SUCCESS"
export const UPDATE_CANDIDATE_DOCUMENT_FAIL = "UPDATE_CANDIDATE_DOCUMENT_FAIL"

export const DELETE_CANDIDATE_DOCUMENT = "DELETE_CANDIDATE_DOCUMENT"
export const DELETE_CANDIDATE_DOCUMENT_SUCCESS =
  "DELETE_CANDIDATE_DOCUMENT_SUCCESS"
export const DELETE_CANDIDATE_DOCUMENT_FAIL = "DELETE_CANDIDATE_DOCUMENT_FAIL"

export const DELETE_CANDIDATE = "DELETE_CANDIDATE"
export const DELETE_CANDIDATE_SUCCESS = "DELETE_CANDIDATE_SUCCESS"
export const DELETE_CANDIDATE_FAIL = "DELETE_CANDIDATE_FAIL"

//agentes
export const GET_AGENTES = "GET_AGENTES"
export const GET_AGENTES_SUCCESS = "GET_AGENTES_SUCCESS"
export const GET_AGENTES_FAIL = "GET_AGENTES_FAIL"

export const GET_AGENTE_DETAIL = "GET_AGENTE_DETAIL"
export const GET_AGENTE_DETAIL_SUCCESS = "GET_AGENTE_DETAIL_SUCCESS"
export const GET_AGENTE_DETAIL_FAIL = "GET_AGENTE_DETAIL_FAIL"

export const CREATE_AGENTE = "CREATE_AGENTE"
export const CREATE_AGENTE_SUCCESS = "CREATE_AGENTE_SUCCESS"
export const CREATE_AGENTE_FAIL = "CREATE_AGENTE_FAIL"

export const UPDATE_AGENTE = "UPDATE_AGENTE"
export const UPDATE_AGENTE_SUCCESS = "UPDATE_AGENTE_SUCCESS"
export const UPDATE_AGENTE_FAIL = "UPDATE_AGENTE_FAIL"

export const DELETE_AGENTE = "DELETE_AGENTE"
export const DELETE_AGENTE_SUCCESS = "DELETE_AGENTE_SUCCESS"
export const DELETE_AGENTE_FAIL = "DELETE_AGENTE_FAIL"

//company

export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL"

export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL"
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS"
export const GET_COMPANY_DETAIL_FAIL = "GET_COMPANY_DETAIL_FAIL"

export const CREATE_COMPANY = "CREATE_COMPANY"
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS"
export const CREATE_COMPANY_FAIL = "CREATE_COMPANY_FAIL"

export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"

export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"
