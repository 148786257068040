import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Badge,
  FormGroup,
} from "reactstrap"
import { Link, useHistory, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
import Select from "react-select"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { CSVLink, CSVDownload } from "react-csv"

import "../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import {
  deleteAccount,
  getAccountCategory,
  getAccounts,
  showAccountPopup,
  showDeletePopup,
} from "store/actions"
import DeleteModal from "components/Common/DeleteModal"
import CreateAccount from "./Crud/Create"
import PropTypes from "prop-types"
import moment from "moment"

const Account = ({ isTransation, candidateId, isAccountByCat }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const { accounts, loading, accountCategory, catLoading, singleCandidate } =
    useSelector(state => ({
      accounts: state.Accounts.accounts,
      loading: state.Accounts.loading,
      accountCategory: state.Accounts.accountCategory,
      catLoading: state.Accounts.accountCategoryLoad,
      singleCandidate: state.Users.singleCandidate,
    }))

  const currentYear = new Date().getFullYear()
  const firstDay = new Date(currentYear, 0, 1)
  const lastDay = new Date(currentYear, 11, 31)

  const [date, setDate] = useState({
    start_date: moment(firstDay).format("YYYY-MM-DD"),
    end_date: moment(lastDay).format("YYYY-MM-DD"),
  })

  //acccat
  const [categorySelect, setCategorySelect] = useState()
  const [categorySearch, setCategorySearch] = useState()
  const [categoryid, setCategoryId] = useState()

  const debounceCategorySearch = debounce(
    value => setCategorySearch(value),
    600
  )

  const handleCategoryEnters = textEntered => {
    debounceCategorySearch(textEntered)
  }

  function handlerCategoryFinalValue(event) {
    const category = {
      ...categorySelect,
      title: event.label,
      id: event.value,
    }
    setCategorySelect(category)
    setCategoryId(event.value)
  }
  const categoryOptions = [
    {
      options: accountCategory?.results?.map((result, index) => ({
        key: index,
        label: result.category_name,
        value: result.id,
      })),
    },
  ]

  useEffect(() => {
    if (isAccountByCat) {
      dispatch(getAccountCategory(categorySearch))
    }
  }, [dispatch, categorySearch])

  const [sort, setSort] = useState("all")

  const listChange = sortValue => {
    setSort(sortValue)
  }

  useEffect(() => {
    if (params) {
      setCategoryId(params?.id)
    }
  }, [params])

  useEffect(() => {
    if (categoryid && isAccountByCat) {
      history.push(`/account/category/${categoryid}`)
    }
  }, [categoryid])

  //pages
  const totalPages = Math.ceil(accounts?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }
  useEffect(() => {
    if (candidateId) {
      dispatch(getAccounts(searchText, pageSend(), sort, candidateId, "", ""))
    } else if (categoryid || params) {
      dispatch(getAccounts(searchText, pageSend(), sort, "", categoryid, ""))
    } else if (date) {
      dispatch(getAccounts(searchText, pageSend(), sort, "", "", date))
    } else {
      dispatch(getAccounts(searchText, pageSend(), sort, "", "", ""))
    }
  }, [dispatch, page, searchText, sort, categoryid, date, params])

  const [itemId, setItemId] = useState()
  const handleDelete = id => {
    setItemId(id)
    dispatch(showDeletePopup())
  }
  const onDeleteFun = () => {
    if (itemId) {
      dispatch(deleteAccount(itemId))
    }
  }

  const columns = [
    {
      dataField: "auto_id",
      text: "Sl No",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "worker_name",
      text: "Candidate Name",
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "category_name",
      text: "Account Category",
    },
    // {
    //   dataField: "type",
    //   text: "Type",
    // },
    {
      dataField: "transaction_type",
      text: "Transaction",
    },
    {
      dataField: "amount",
      text: "Amount",
    },

    // {
    //   dataField: "company_name",
    //   text: "Company",
    // },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const handleAccounts = () => {
    if (isAccountByCat && params?.id) {
      return (
        accounts?.results?.filter(i => i.account_category === params?.id) ?? []
      )
    } else {
      return accounts?.results ?? []
    }
  }

  const salesmansData = map(handleAccounts(), (item, index) => ({
    ...item,
    key: index,
    date_added: <span>{moment(item?.date_added).format("YYYY-MM-DD")}</span>,

    transaction_type: (
      <Badge
        className={`font-size-12 mx-2 mb-2 badge-soft-${
          item?.transaction_type === "credit" ? "success" : "danger"
        } p-2`}
        pill
      >
        {item?.transaction_type ? item?.transaction_type : ""}
      </Badge>
    ),
    category_name: (
      <Link to={`/account/category/${item?.account_category}`} className="">
        {item?.category_name}
      </Link>
    ),
    action: (
      <div className="d-flex align-items-center ">
        <Link to={`/renew/account/${item?.id}`} className="btn-light btn-sm">
          Edit
        </Link>
        <i
          onClick={() => {
            handleDelete(item.id)
          }}
          className="bx bx-trash mx-1"
          style={{ fontSize: "16px", cursor: "pointer" }}
        />
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }
  const openAccountModal = () => {
    dispatch(showAccountPopup())
  }

  const newProfit =
    singleCandidate?.total_income - singleCandidate?.total_expense

  const headers = [
    { label: "Sl No", key: "auto_id" },
    { label: "Date", key: "date_added" },
    { label: "Candidate Name", key: "worker_name" },
    { label: "Description", key: "description" },
    { label: "Account Category", key: "category_name" },
    { label: "Credit", key: "credit" },
    { label: "Debit", key: "debit" },
    // { label: "Amount", key: "amount" },
  ]

  const totalCredit = handleAccounts()
    ?.map(i => (i?.transaction_type === "credit" ? parseInt(i.amount) : 0))
    ?.reduce((a, b) => a + b, 0)
  const totalDebit = handleAccounts()
    ?.map(i => (i?.transaction_type === "debit" ? parseInt(i.amount) : 0))
    ?.reduce((a, b) => a + b, 0)

  const finalDataToExport = [
    ...handleAccounts(),
    {},
    {
      category_name: "Total",
      credit: totalCredit,
      debit: totalDebit,
    },
  ]

  const excelDownloadTransations = map(finalDataToExport, (item, key) => ({
    ...item,
    auto_id: item.auto_id,
    date_added: item.date ? moment(item.date).format("YYYY-MM-DD") : "",
    worker_name: item?.worker_name,
    description: item.description,
    category_name: item.category_name,
    credit: item.transaction_type === "credit" ? item.amount : item?.credit,
    debit: item.transaction_type === "debit" ? item.amount : item?.debit,

    // amount: item.amount,
  }))

  return (
    <React.Fragment>
      <CreateAccount candidateId={candidateId} />
      <DeleteModal onDeleteClick={onDeleteFun} />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody style={{ minHeight: "300px" }}>
              {isAccountByCat ? (
                <Row>
                  <Col className={`col-6 mb-4`}>
                    <FormGroup className="w-100 custom_store_select">
                      <div className="ajax-select mt-lg-0 select2-container new_custom_store_select d-flex w-100 align-items-center">
                        <p
                          className="text-muted m-0 mx-2 p-0 "
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Select a Category :
                        </p>
                        <Select
                          onInputChange={handleCategoryEnters}
                          value={categorySelect?.title}
                          placeholder={categorySelect?.title}
                          onChange={handlerCategoryFinalValue}
                          options={categoryOptions}
                          classNamePrefix="select2-selection custom_select"
                          isLoading={catLoading}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                <Row className="mb-3">
                  <Col xl={2} lg={2} md={12} sm={6} className="">
                    <div
                      className="btn-group mt-xl-0"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autoComplete="off"
                        defaultChecked
                        onChange={() => listChange("all")}
                      />
                      <label className="btn btn-secondary" htmlFor="btnradio1">
                        All
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autoComplete="off"
                        onChange={() => listChange("income")}
                      />
                      <label className="btn btn-secondary" htmlFor="btnradio2">
                        Income
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio3"
                        autoComplete="off"
                        onChange={() => listChange("expense")}
                      />
                      <label className="btn btn-secondary" htmlFor="btnradio3">
                        Expences
                      </label>
                    </div>
                  </Col>
                  {candidateId ? (
                    <Col xl={6} lg={6} md={6} sm={6}>
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="mx-3">
                          <h6 className="text-muted">Income</h6>
                          <Badge
                            className={`font-size-18 mb-2 badge-soft-success p-2`}
                            pill
                          >
                            {singleCandidate?.total_income ?? 0}
                          </Badge>
                        </div>
                        <div className="mx-3">
                          <h6 className="text-muted">Expence</h6>
                          <Badge
                            className={`font-size-18 mb-2 badge-soft-danger p-2`}
                            pill
                          >
                            {singleCandidate?.total_expense ?? 0}
                          </Badge>
                        </div>
                        <div className="mx-3">
                          <h6 className="text-muted">Profit</h6>
                          <Badge
                            className={`font-size-18 mb-2 badge-soft-success p-2`}
                            pill
                          >
                            {newProfit ? newProfit : 0}
                          </Badge>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <Col xl={6} lg={6} md={6}>
                      <div className="d-flex align-items-center justify-content-center">
                        <label
                          className="mx-3 mb-0"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Filter by date
                        </label>
                        <form className="d-flex">
                          <input
                            name="start_date"
                            className="form-control"
                            type="date"
                            id="example-date-input"
                            onChange={e =>
                              setDate({
                                ...date,
                                start_date: moment(e.target.value).format(
                                  "YYYY-MM-DD"
                                ),
                                isDate: true,
                              })
                            }
                            value={date.start_date}
                          />
                          <p>&nbsp;&nbsp;</p>
                          <input
                            name="end_date"
                            className="form-control"
                            type="date"
                            id="example-date-input"
                            onChange={e =>
                              setDate({
                                ...date,
                                end_date: moment(e.target.value).format(
                                  "YYYY-MM-DD"
                                ),
                                isDate: true,
                              })
                            }
                            value={date.end_date}
                          />
                          {date.isDate ? (
                            <button
                              className="btn bg-light mx-1"
                              onClick={() =>
                                setDate({
                                  ...date,
                                  end_date: moment(new Date()).format(
                                    "YYYY-MM-DD"
                                  ),
                                  start_date: moment(new Date()).format(
                                    "YYYY-MM-DD"
                                  ),
                                  isDate: false,
                                })
                              }
                            >
                              Reset
                            </button>
                          ) : (
                            ""
                          )}
                        </form>
                      </div>
                    </Col>
                  )}
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={6}
                    className="d-flex align-items-center justify-content-end"
                  >
                    <Button
                      data-tip
                      data-for="downloadTip"
                      className="w-md btn btn-info m-auto me-2 mt-0 mb-2 d-block"
                    >
                      <CSVLink
                        data={excelDownloadTransations}
                        headers={headers}
                        filename={"whalesood-transaction.xlsx"}
                      >
                        <div className="d-flex">
                          <i
                            className="bx bx-download mx-1"
                            style={{ fontSize: "18px", color: "white" }}
                          />{" "}
                          <span className="text-white">Download Excel</span>
                        </div>
                      </CSVLink>
                    </Button>
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="success"
                        className="btn mb-2 me-2"
                        onClick={openAccountModal}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Create New Entry
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
              <ToolkitProvider
                keyField="id"
                columns={
                  // candidateId
                  // ? columns?.filter(i => i.dataField !== "worker_name")
                  // :
                  columns
                }
                data={salesmansData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      {isTransation || isAccountByCat ? (
                        <></>
                      ) : (
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <form
                                className="app-search d-lg-block"
                                onChange={e => handleSearch(e)}
                              >
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    defaultValue={searchText}
                                  />
                                  <span className="bx bx-search-alt" />
                                </div>
                              </form>
                            </div>
                          </div>
                        </Col>
                      )}
                      <Col md="4"></Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {isAccountByCat ? (
                          <>
                            {params?.id ? (
                              <>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <MyPagination
                                  pages={pages}
                                  clcickedPage={page}
                                  onNunClick={item => setPage(item)}
                                  onNextClick={() => setPage(page + 1)}
                                  onPrevClick={() => setPage(page - 1)}
                                  onFastNextClick={() => setPage(pages.length)}
                                  onFastPrevClick={() => setPage(1)}
                                  apiPage={pageSend}
                                />
                              </>
                            ) : (
                              <p></p>
                            )}
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <MyPagination
                              pages={pages}
                              clcickedPage={page}
                              onNunClick={item => setPage(item)}
                              onNextClick={() => setPage(page + 1)}
                              onPrevClick={() => setPage(page - 1)}
                              onFastNextClick={() => setPage(pages.length)}
                              onFastPrevClick={() => setPage(1)}
                              apiPage={pageSend}
                            />
                          </>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Account
Account.propTypes = {
  isTransation: PropTypes.bool,
  isAccountByCat: PropTypes.bool,
  candidateId: PropTypes.any,
}
