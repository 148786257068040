import React, { useEffect, useState } from "react"
import { Row, Col, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import "../../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import {
  deleteAccount,
  getAccountCategory,
  getAccounts,
  showDeletePopup,
} from "store/actions"
import DeleteModal from "components/Common/DeleteModal"
import PropTypes from "prop-types"
import CreateAccount from "../Crud/Create"
import moment from "moment"

const Account = ({ sort, accounts, candidateId, date }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)

  const { loading, isAccountPopup } = useSelector(state => ({
    loading: state.Accounts.loading,
    isAccountPopup: state.Layout.isAccountPopup,
  }))

  //pages
  const totalPages = Math.ceil(accounts?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    if (date) {
      dispatch(getAccountCategory("", pageSend(), sort, date))
    } else {
      dispatch(getAccountCategory("", pageSend(), sort))
    }
  }, [dispatch, page, sort, date, isAccountPopup])

  const [itemId, setItemId] = useState()

  const handleDelete = id => {
    setItemId(id)
    dispatch(showDeletePopup())
  }
  const onDeleteFun = () => {
    if (itemId) {
      dispatch(deleteAccount(itemId))
    }
  }

  const columns = [
    {
      dataField: "auto_id",
      text: "Sl No",
      sort: true,
    },
    // { dataField: "date_added", text: "Date", sort: true },
    // {
    //   dataField: "worker_name",
    //   text: "Candidate Name",
    // },
    // {
    //   dataField: "description",
    //   text: "Description",
    // },
    {
      dataField: "category_name",
      text: "Account Category",
    },
    // {
    //   dataField: "type",
    //   text: "Type",
    // },
    // {
    //   dataField: "transaction_type",
    //   text: "Transaction",
    // },
    {
      dataField: "total_amount",
      text: "Amount",
    },

    // {
    //   dataField: "action",
    //   text: "Action",
    // },
  ]

  const salesmansData = map(accounts?.results, (item, index) => ({
    ...item,
    key: index,
    category_name: (
      <Link to={`/account/category/${item?.id}`} className="">
        {item?.category_name}
      </Link>
    ),
    date_added: <span>{moment(item?.date_added).format("YYYY-MM-DD")}</span>,
    action: (
      <div className="d-flex align-items-center ">
        {/* <Link to={`/renew/account/${item?.id}`} className="btn-light btn-sm">
          Edit
        </Link> */}
        <i
          onClick={() => {
            handleDelete(item.id)
          }}
          className="bx bx-trash mx-1"
          style={{ fontSize: "16px", cursor: "pointer" }}
        />
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  return (
    <React.Fragment>
      <CreateAccount />
      <DeleteModal onDeleteClick={onDeleteFun} />
      <Row>
        <Col className="col-12">
          <ToolkitProvider
            keyField="id"
            columns={columns}
            data={salesmansData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                    <MyPagination
                      pages={pages}
                      clcickedPage={page}
                      onNunClick={item => setPage(item)}
                      onNextClick={() => setPage(page + 1)}
                      onPrevClick={() => setPage(page - 1)}
                      onFastNextClick={() => setPage(pages.length)}
                      onFastPrevClick={() => setPage(1)}
                      apiPage={pageSend}
                    />
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Account
Account.propTypes = {
  sort: PropTypes.any,
  accounts: PropTypes.any,
  candidateId: PropTypes.any,
  date: PropTypes.any,
}
