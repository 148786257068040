import { call, put, takeEvery } from "redux-saga/effects"

import { get, post, ApiPut, del, patch, BASE_URL } from "helpers/api_methods"
import {
  createAgenteFail,
  createAgenteSuccess,
  createCandidateFail,
  createCandidateSuccess,
  createCompanyFail,
  createCompanySuccess,
  deleteAgenteFail,
  deleteAgenteSuccess,
  deleteCandidateDocumentsFail,
  deleteCandidateDocumentsSuccess,
  deleteCandidateFail,
  deleteCandidateSuccess,
  deleteCompanyFail,
  deleteCompanySuccess,
  getAgenteDetailFail,
  getAgenteDetailSuccess,
  getAgentessFail,
  getAgentesSuccess,
  getCandidateDetailFail,
  getCandidateDetailSuccess,
  getCandidatePiachartFail,
  getCandidatePiachartSuccess,
  getCandidatesFail,
  getCandidatesSuccess,
  getCompaniesFail,
  getCompaniesSuccess,
  getCompanyDetailFail,
  getCompanyDetailSuccess,
  updateAgenteFail,
  updateAgenteSuccess,
  updateCandidateDocumentsFail,
  updateCandidateDocumentsSuccess,
  updateCandidateFail,
  updateCandidateSuccess,
  updateCompanyFail,
  updateCompanySuccess,
  uploadCandidateDocumentsFail,
  uploadCandidateDocumentsSuccess,
  uploadCandidatePicFail,
  uploadCandidatePicSuccess,
} from "./actions"
import { doneNotification, errorNotification } from "constants/notification"
import {
  CREATE_AGENTE,
  CREATE_CANDIDATE,
  CREATE_COMPANY,
  DELETE_AGENTE,
  DELETE_CANDIDATE,
  DELETE_CANDIDATE_DOCUMENT,
  DELETE_COMPANY,
  GET_AGENTES,
  GET_AGENTE_DETAIL,
  GET_CANDIDATES,
  GET_CANDIDATE_DETAIL,
  GET_CANDIDATE_PIACHART,
  GET_COMPANIES,
  GET_COMPANY_DETAIL,
  UPDATE_AGENTE,
  UPDATE_CANDIDATE,
  UPDATE_CANDIDATE_DOCUMENT,
  UPDATE_COMPANY,
  UPLOAD_CANDIDATE_DOCUMENT,
  UPLOAD_CANDIDATE_PIC,
} from "./actionTypes"
import { hideDeletePopup } from "store/actions"
import axios from "axios"

const middleWareUrl = "/api/v1"

//candidates
const getCandidateAPi = ({ searchText, page, sort }) => {
  if (searchText) {
    return get(
      `${middleWareUrl}/worker/worker/?status=${sort ? sort : ""}&search=${
        searchText && searchText
      }`
    )
  } else {
    return get(
      `${middleWareUrl}/worker/worker/?page=${page ? page : 1}&status=${
        sort ? sort : ""
      }`
    )
  }
}

const getCandidatePiachartApi = ({ candId, type, date }) => {
  if (type) {
    return get(
      `${middleWareUrl}/account/accounts_category_chart/?type=${type}&start_date=${date.start_date}&end_date=${date.end_date}`
    )
  } else {
    return get(
      `${middleWareUrl}/account/accounts_category_chart/?candidate=${candId}`
    )
  }
}
const getCandidateDetailsAPi = candId => {
  return get(`${middleWareUrl}/worker/worker/${candId}`)
}
const createCandidateApi = ({ candidate }) => {
  return post(`${middleWareUrl}/worker/worker/`, candidate)
}
const updateCandidateApi = ({ candId, candidate }) => {
  return ApiPut(`${middleWareUrl}/worker/worker/${candId}/`, candidate)
}
const uploadCandidatePicApi = ({ candId, candidate }) => {
  return patch(`${middleWareUrl}/worker/worker/${candId}/`, candidate)
}

const onUploadCandidateDocumentApi = async ({ candidate, documents }) => {
  console.log("title =>")
  console.log("data :", candidate, documents)
  const form_data = new FormData()
  {
    documents?.image &&
      documents?.image[0] &&
      form_data.append(
        "document",
        documents?.image[0],
        documents?.image[0]?.name
      ),
      form_data.append("worker", candidate?.worker)
    form_data.append("document_name", candidate?.document_name)
  }
  try {
    let response = await axios({
      method: "post",
      url: `${BASE_URL}${middleWareUrl}/worker/document/`,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "token " + localStorage.getItem("token"),
      },
      data: form_data,
    })
    return response.data
  } catch (err) {
    return err?.response?.data
  }
}

const onUpdateCandidateDocumentApi = async ({ candidate, documents }) => {
  const form_data = new FormData()
  {
    documents?.image &&
      documents?.image[0] &&
      form_data.append(
        "document",
        documents?.image[0],
        documents?.image[0]?.name
      ),
      form_data.append("worker", candidate?.worker)
    form_data.append("document_name", candidate?.document_name)
  }
  try {
    let response = await axios({
      method: "patch",
      url: `${BASE_URL}${middleWareUrl}/worker/document/${candidate?.documentId}/`,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "token " + localStorage.getItem("token"),
      },
      data: form_data,
    })
    return response.data
  } catch (err) {
    return err?.response?.data
  }
}

const onDeleteCandidateDocumentApi = ({ documentId }) => {
  return del(`${middleWareUrl}/worker/document/${documentId}/`)
}

const deleteCandidateApi = ({ candId }) => {
  return del(`${middleWareUrl}/worker/worker/${candId}/`)
}

function* fetchCandidate({ payload }) {
  try {
    const response = yield call(getCandidateAPi, payload)
    yield put(getCandidatesSuccess(response))
  } catch (error) {
    yield put(getCandidatesFail(error))
  }
}
function* fetchCandidateDetail({ candId }) {
  try {
    const response = yield call(getCandidateDetailsAPi, candId)
    yield put(getCandidateDetailSuccess(response))
  } catch (error) {
    yield put(getCandidateDetailFail(error))
  }
}
function* onCreateCandidate({ payload }) {
  try {
    const response = yield call(createCandidateApi, payload)
    if (response?.id) {
      yield put(createCandidateSuccess(response))
      payload.history.push("/candidates")
      doneNotification("Candidate Created Success")
    } else {
      yield put(createCandidateFail(response))
    }
  } catch (error) {
    yield put(createCandidateFail(error))
    errorNotification()
  }
}

function* fetchCandidatePiachart({ payload }) {
  try {
    const response = yield call(getCandidatePiachartApi, payload)
    yield put(getCandidatePiachartSuccess(response))
  } catch (error) {
    yield put(getCandidatePiachartFail(error))
  }
}

function* onUpdateCandidate({ payload }) {
  try {
    const response = yield call(updateCandidateApi, payload)
    if (response?.id) {
      yield put(updateCandidateSuccess(response))
      doneNotification()
      if (payload.history) {
        payload.history.goBack()
      }
    } else {
      yield put(updateCandidateFail(response))
    }
  } catch (error) {
    yield put(updateCandidateFail(error))
    errorNotification()
  }
}

function* onUploadCandidatePic({ payload }) {
  try {
    const response = yield call(uploadCandidatePicApi, payload)
    if (response?.id) {
      yield put(uploadCandidatePicSuccess(response))
      doneNotification()
    } else {
      yield put(uploadCandidatePicFail(response))
    }
  } catch (error) {
    yield put(uploadCandidatePicFail(error))
    errorNotification()
  }
}

function* onUploadCandidateDocument({ payload }) {
  try {
    const response = yield call(onUploadCandidateDocumentApi, payload)
    if (response?.id) {
      yield put(uploadCandidateDocumentsSuccess(response))
      doneNotification()
      payload.onCloseClick()
    } else {
      yield put(uploadCandidateDocumentsFail(response))
      errorNotification()
    }
  } catch (error) {
    console.log("data :", error)
    yield put(uploadCandidateDocumentsFail(error))
    errorNotification()
  }
}

function* onUpdateCandidateDocument({ payload }) {
  try {
    const response = yield call(onUpdateCandidateDocumentApi, payload)
    if (response?.id) {
      yield put(updateCandidateDocumentsSuccess(response))
      doneNotification()
      payload.onCloseClick()
    } else {
      yield put(updateCandidateDocumentsFail(response))
      errorNotification()
    }
  } catch (error) {
    console.log("data :", error)
    yield put(updateCandidateDocumentsFail(error))
    errorNotification()
  }
}
function* onDeleteCandidateDocument({ payload }) {
  try {
    const response = yield call(onDeleteCandidateDocumentApi, payload)
    if (response) {
      yield put(deleteCandidateDocumentsSuccess(payload.documentId))
      doneNotification()
    }
  } catch (error) {
    console.log("data :", error)
    yield put(deleteCandidateDocumentsFail(error))
    errorNotification()
  }
}

function* onDeleteCandidate({ payload }) {
  try {
    const response = yield call(deleteCandidateApi, payload)
    if (response) {
      yield put(deleteCandidateSuccess(payload.candId))
      doneNotification()
      payload.history.push("/candidates")
      yield put(hideDeletePopup())
    }
  } catch (error) {
    yield put(deleteCandidateFail(error))
    errorNotification()
  }
}

//agent
const getAgenteAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `${middleWareUrl}/agent/agent/?search=${searchText && searchText}`
    )
  } else {
    return get(`${middleWareUrl}/agent/agent/?page=${page ? page : 1}`)
  }
}
const getAgenteDetailsAPi = ({ agentId }) => {
  return get(`${middleWareUrl}/agent/agent/${agentId}`)
}
const createAgenteApi = ({ agent }) => {
  return post(`${middleWareUrl}/agent/agent/`, agent)
}
const updateAgenteApi = ({ agentId, agent }) => {
  return ApiPut(`${middleWareUrl}/agent/agent/${agentId}/`, agent)
}
const deleteAgenteApi = ({ agentId }) => {
  return del(`${middleWareUrl}/agent/agent/${agentId}/`)
}

function* fetchAgente({ payload }) {
  try {
    const response = yield call(getAgenteAPi, payload)
    yield put(getAgentesSuccess(response))
  } catch (error) {
    yield put(getAgentessFail(error))
  }
}
function* fetchAgenteDetail(candId) {
  try {
    const response = yield call(getAgenteDetailsAPi, candId)
    yield put(getAgenteDetailSuccess(response))
  } catch (error) {
    yield put(getAgenteDetailFail(error))
  }
}
function* onCreateAgente({ payload }) {
  try {
    const response = yield call(createAgenteApi, payload)
    if (response?.id) {
      yield put(createAgenteSuccess(response))
      payload.history.push("/agents")
      doneNotification("Agente Created Success")
    } else {
      yield put(createAgenteFail(response))
    }
  } catch (error) {
    yield put(createAgenteFail(error))
    errorNotification()
  }
}

function* onUpdateAgente({ payload }) {
  try {
    const response = yield call(updateAgenteApi, payload)
    if (response?.id) {
      yield put(updateAgenteSuccess(response))
      doneNotification()
      payload.history.goBack()
    } else {
      yield put(updateAgenteFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(updateAgenteFail(error))
    errorNotification()
  }
}

function* onDeleteAgente({ payload }) {
  try {
    const response = yield call(deleteAgenteApi, payload)
    if (response) {
      yield put(deleteAgenteSuccess(payload.agentId))
      doneNotification()
      yield put(hideDeletePopup())
    }
  } catch (error) {
    yield put(deleteAgenteFail(error))
    errorNotification()
  }
}

//company
const getCompanyAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(
      `${middleWareUrl}/company/company/?search=${searchText && searchText}`
    )
  } else {
    return get(`${middleWareUrl}/company/company/?page=${page ? page : 1}`)
  }
}
const getCompanyDetailsAPi = companyId => {
  return get(`${middleWareUrl}/company/company/${companyId}`)
}
const createCompanyApi = ({ company }) => {
  return post(`${middleWareUrl}/company/company/`, company)
}
const updateCompanyApi = ({ companyId, company }) => {
  return ApiPut(`${middleWareUrl}/company/company/${companyId}/`, company)
}
const deleteCompanyApi = ({ companyId }) => {
  return del(`${middleWareUrl}/company/company/${companyId}/`)
}

function* fetchCompany({ payload }) {
  try {
    const response = yield call(getCompanyAPi, payload)
    yield put(getCompaniesSuccess(response))
  } catch (error) {
    yield put(getCompaniesFail(error))
  }
}
function* fetchCompanyDetail({ companyId }) {
  try {
    const response = yield call(getCompanyDetailsAPi, companyId)
    yield put(getCompanyDetailSuccess(response))
  } catch (error) {
    yield put(getCompanyDetailFail(error))
  }
}
function* onCreateCompany({ payload }) {
  try {
    const response = yield call(createCompanyApi, payload)
    if (response?.id) {
      yield put(createCompanySuccess(response))
      payload.history.push("/companies")
      doneNotification("Company Created Success")
    } else {
      yield put(createCompanyFail(response))
    }
  } catch (error) {
    yield put(createCompanyFail(error))
    errorNotification()
  }
}

function* onUpdateCompany({ payload }) {
  try {
    const response = yield call(updateCompanyApi, payload)
    if (response?.id) {
      yield put(updateCompanySuccess(response))
      doneNotification()
    } else {
      yield put(updateCompanyFail(response))
    }
  } catch (error) {
    yield put(updateCompanyFail(error))
    errorNotification()
  }
}

function* onDeleteCompany({ payload }) {
  try {
    const response = yield call(deleteCompanyApi, payload)
    if (response) {
      yield put(deleteCompanySuccess(payload.companyId))
      doneNotification()
      yield put(hideDeletePopup())
    }
  } catch (error) {
    yield put(deleteCompanyFail(error))
    errorNotification()
  }
}

function* UsersSaga() {
  yield takeEvery(GET_CANDIDATES, fetchCandidate)
  yield takeEvery(GET_CANDIDATE_DETAIL, fetchCandidateDetail)
  yield takeEvery(CREATE_CANDIDATE, onCreateCandidate)
  yield takeEvery(UPDATE_CANDIDATE, onUpdateCandidate)
  yield takeEvery(UPLOAD_CANDIDATE_PIC, onUploadCandidatePic)
  yield takeEvery(UPLOAD_CANDIDATE_DOCUMENT, onUploadCandidateDocument)
  yield takeEvery(UPDATE_CANDIDATE_DOCUMENT, onUpdateCandidateDocument)
  yield takeEvery(DELETE_CANDIDATE_DOCUMENT, onDeleteCandidateDocument)
  yield takeEvery(DELETE_CANDIDATE, onDeleteCandidate)

  yield takeEvery(GET_AGENTES, fetchAgente)
  yield takeEvery(GET_AGENTE_DETAIL, fetchAgenteDetail)
  yield takeEvery(CREATE_AGENTE, onCreateAgente)
  yield takeEvery(UPDATE_AGENTE, onUpdateAgente)
  yield takeEvery(DELETE_AGENTE, onDeleteAgente)

  yield takeEvery(GET_COMPANIES, fetchCompany)
  yield takeEvery(GET_COMPANY_DETAIL, fetchCompanyDetail)
  yield takeEvery(CREATE_COMPANY, onCreateCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
  yield takeEvery(DELETE_COMPANY, onDeleteCompany)
  yield takeEvery(GET_CANDIDATE_PIACHART, fetchCandidatePiachart)
}

export default UsersSaga
