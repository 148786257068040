import MetaTag from "constants/Meta-tag"
import AccountChart from "pages/account/comp/AccountChart"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { getDashboardData } from "store/actions"
import YearlyChart from "./charts/YearlyChart"
import DashboardCard from "./MiniCard"

function index() {
  const dispatch = useDispatch()

  const { dashboardLoading, dashboardData } = useSelector(state => ({
    dashboardLoading: state.Dashboard.dashboardLoading,
    dashboardData: state.Dashboard.dashboardData,
  }))

  useEffect(() => {
    dispatch(getDashboardData())
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title="Shops " />

        <Container fluid>
          <Row>
            <Col sm="8">
              <YearlyChart />

              <DashboardCard isSub />
              {/* <MonthlyChart /> */}
            </Col>
            <Col sm="4">
              {/* <DefualtComponent /> */}
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to={"#"}>
                    <div className="d-flex flex-wrap justify-content-between">
                      <div className="me-3">
                        <p className="text-muted mb-2">Candidates</p>
                        <h5 className="mb-0">{dashboardData?.companies}</h5>
                      </div>
                      <Link
                        className="btn btn-lg btn-success font-size-13 d-flex align-items-center"
                        to={"/make/candidate"}
                      >
                        <p className="mb-0">Create Candidate </p>
                      </Link>
                    </div>
                  </Link>
                </CardBody>
              </Card>
              <DashboardCard />
            </Col>
          </Row>
          <Row>
            <AccountChart />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
