import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DocumentModal from "./DocumentModal"
import docs from "../../../assets/images/logo/doc.jpg"
import { AiOutlinePlus } from "react-icons/ai"
import { MdDelete } from "react-icons/md"
import { RiEditBoxLine } from "react-icons/ri"
import { deleteCandidateDocuments } from "store/actions"
import { map } from "lodash"
import { useFileExtension } from "components/hooks/useFileExtension"

function DocumentUpload() {
  const dispatch = useDispatch()

  const { documents } = useSelector(state => ({
    documents: state.Users.singleCandidate?.documents,
  }))

  const [modal, setModal] = useState({ status: false, data: {} })

  const handleImages = url => {
    const extension = useFileExtension(url)

    switch (extension) {
      case "jpeg":
      case "png":
      case "jpg":
        return "image"
      default:
        return "doc"
    }
  }

  return (
    <div className="mt-2 w-100">
      <span>Upload Documents</span>
      <div className="d-flex align-items-start mt-1">
        {map(documents, (doc, key) => (
          <div
            key={key}
            className="me-3 position-relative"
            style={{
              padding: "10px 6px",
              textAlign: "center",
              borderRadius: "5px",
              background: "#f0f2f6",
              minHeight: 100,
              width: 100,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {handleImages(doc?.document) === "image" ? (
              <>
                <img
                  className="dotted rounded"
                  style={{ height: "80px", width: "80px" }}
                  src={doc?.document}
                />
                <p className="pt-2 font-size-11 pb-0 mb-0">
                  {doc.document_name}
                </p>
              </>
            ) : (
              <>
                <img
                  className="dotted rounded"
                  style={{ height: "50px", width: "50px" }}
                  src={docs}
                />
                <p className="pt-2 font-size-11 pb-0 mb-0">
                  {doc.document_name}
                </p>
              </>
            )}
            <div className="top-icons d-flex">
              <div
                className="delete-icon "
                title="Delete document"
                onClick={() => dispatch(deleteCandidateDocuments(doc?.id))}
              >
                <MdDelete />
              </div>
              <div
                className="edit-icon"
                title="re-upload document"
                onClick={() =>
                  setModal({
                    status: true,
                    data: doc,
                  })
                }
              >
                <RiEditBoxLine />
              </div>
            </div>
          </div>
        ))}

        <div
          className="needsclick "
          onClick={() =>
            setModal({
              status: true,
              data: {},
            })
          }
          style={{ cursor: "pointer" }}
        >
          <div
            className="mb-3 m-0 d-flex align-items-center justify-content-center flex-column file-box text-center rounded image-i-size "
            style={{
              width: 100,
              height: 100,
              border: "1px solid #f0f2f6",
            }}
          >
            <p className="p-0 m-0 font-size-12 pb-1">Upload Document</p>
            <AiOutlinePlus color={"#333"} />
          </div>
        </div>
      </div>
      <DocumentModal
        onCloseClick={() =>
          setModal({
            status: false,
            data: {},
          })
        }
        show={modal?.status}
        data={modal?.data}
      />
    </div>
  )
}

export default DocumentUpload
