import React from "react"
import { useSelector } from "react-redux"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

function DashboardCard({ isSub }) {
  const { dashboardLoading, dashboardData } = useSelector(state => ({
    dashboardLoading: state.Dashboard.dashboardLoading,
    dashboardData: state.Dashboard.dashboardData,
  }))

  const cardData = [
    // {
    //   title: "Total Income",
    //   cardValue: dashboardData?.total_income,
    //   iconClass: "wallet-alt",
    //   routeLink: "/dashboard",
    //   loading: dashboardLoading,
    //   alt: "",
    // },
    // {
    //   title: "Total Expense",
    //   cardValue: dashboardData?.total_expense,
    //   iconClass: "file-blank",
    //   routeLink: "/dashboard",
    //   loading: dashboardLoading,
    //   alt: "",
    // },
    // {
    //   title: "Candidates",
    //   cardValue: dashboardData?.workers,
    //   iconClass: "notepad",
    //   routeLink: "/candidates",
    //   loading: dashboardLoading,
    //   alt: "No Workers",
    // },
    {
      title: "Companies",
      cardValue: dashboardData?.companies,
      iconClass: "file-blank",
      routeLink: "/companies",
      loading: dashboardLoading,
      alt: "No companies",
    },
    {
      title: "Agents",
      cardValue: dashboardData?.agents,
      iconClass: "bar-chart-alt-2",
      routeLink: "/agents",
      loading: dashboardLoading,
      alt: "No Agents",
    },
    {
      title: "Account Categories",
      cardValue: dashboardData?.account_categories,
      iconClass: "book-bookmark",
      routeLink: "/account/categories",
      loading: dashboardLoading,
      alt: "View all Categories",
    },
    {
      title: "Total Transactions",
      cardValue: dashboardData?.transactions,
      iconClass: "store",
      routeLink: "/transations",
      loading: dashboardLoading,
      alt: "No Transations",
    },
  ]

  const cardDataSub = [
    {
      title: "Pending Candidates",
      cardValue: dashboardData?.pending_workers,
      iconClass: "wallet-alt",
      routeLink: "/candidates",
      loading: dashboardLoading,
      alt: "No candidate Registered",
    },
    {
      title: "Checked Candidate",
      cardValue: dashboardData?.checked_workers,
      iconClass: "file-blank",
      routeLink: "/candidates",
      loading: dashboardLoading,
      alt: "No Checked Candidate yet",
    },
  ]

  const handleCandidateData = () => {
    if (isSub) {
      return cardDataSub
    } else {
      return cardData
    }
  }

  return (
    <>
      <Row className="myScrollCard">
        {handleCandidateData()?.map((item, key) => (
          <Col lg={isSub ? 6 : 12} key={key}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={item.routeLink}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">{item.title}</p>
                      {item.cardValue > 0 && (
                        <h5 className="mb-0">{item.cardValue}</h5>
                      )}
                      <p className="m-0 text-info">
                        {item.cardValue ? <></> : item.alt}
                      </p>
                    </div>

                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className={"bx bxs-" + `${item.iconClass}`}></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default DashboardCard

DashboardCard.propTypes = {
  isSub: PropTypes.any,
}
