import {
  CREATE_CANDIDATE,
  CREATE_CANDIDATE_FAIL,
  CREATE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE,
  DELETE_CANDIDATE_FAIL,
  DELETE_CANDIDATE_SUCCESS,
  GET_CANDIDATES,
  GET_CANDIDATES_FAIL,
  GET_CANDIDATES_SUCCESS,
  GET_CANDIDATE_DETAIL,
  GET_CANDIDATE_DETAIL_FAIL,
  GET_CANDIDATE_DETAIL_SUCCESS,
  UPDATE_CANDIDATE,
  UPDATE_CANDIDATE_FAIL,
  UPDATE_CANDIDATE_SUCCESS,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL_FAIL,
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  GET_AGENTES,
  GET_AGENTES_SUCCESS,
  GET_AGENTES_FAIL,
  GET_AGENTE_DETAIL,
  GET_AGENTE_DETAIL_SUCCESS,
  GET_AGENTE_DETAIL_FAIL,
  CREATE_AGENTE,
  CREATE_AGENTE_SUCCESS,
  CREATE_AGENTE_FAIL,
  UPDATE_AGENTE,
  UPDATE_AGENTE_SUCCESS,
  UPDATE_AGENTE_FAIL,
  DELETE_AGENTE,
  DELETE_AGENTE_SUCCESS,
  DELETE_AGENTE_FAIL,
  CREATE_COMPANY_FAIL,
  GET_CANDIDATE_PIACHART_SUCCESS,
  GET_CANDIDATE_PIACHART_FAIL,
  GET_CANDIDATE_PIACHART,
  UPLOAD_CANDIDATE_PIC_SUCCESS,
  UPLOAD_CANDIDATE_PIC_FAIL,
  UPLOAD_CANDIDATE_DOCUMENT_SUCCESS,
  UPLOAD_CANDIDATE_DOCUMENT_FAIL,
  UPLOAD_CANDIDATE_DOCUMENT,
  DELETE_CANDIDATE_DOCUMENT,
  UPDATE_CANDIDATE_DOCUMENT,
  UPDATE_CANDIDATE_DOCUMENT_FAIL,
  DELETE_CANDIDATE_DOCUMENT_FAIL,
  DELETE_CANDIDATE_DOCUMENT_SUCCESS,
  UPDATE_CANDIDATE_DOCUMENT_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  candidates: [],
  singleCandidate: {},
  agentes: [],
  singleAgente: {},
  company: [],
  singleCompany: {},
  error: {},
  candidatesLoad: false,
  agentesLoad: false,
  companyLoad: false,
  candidateChart: {},
  chartLoad: false,
  candidateDocuments: {},
  documentLoad: false,
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CANDIDATES:
    case GET_CANDIDATE_DETAIL:
    case CREATE_CANDIDATE:
    case UPDATE_CANDIDATE:
    case DELETE_CANDIDATE:
      return {
        ...state,
        candidatesLoad: true,
      }
    case UPLOAD_CANDIDATE_DOCUMENT:
    case DELETE_CANDIDATE_DOCUMENT:
    case UPDATE_CANDIDATE_DOCUMENT:
      return {
        ...state,
        documentLoad: true,
      }
    case GET_CANDIDATES_SUCCESS:
      return {
        ...state,
        candidates: action.payload,
        candidatesLoad: false,
      }

    case GET_CANDIDATES_FAIL:
      return {
        ...state,
        error: action.payload,
        candidatesLoad: false,
      }

    case GET_CANDIDATE_PIACHART:
      return {
        ...state,
        chartLoad: true,
      }

    case GET_CANDIDATE_PIACHART_SUCCESS:
      return {
        ...state,
        candidateChart: action.payload,
        chartLoad: false,
      }

    case GET_CANDIDATE_PIACHART_FAIL:
      return {
        ...state,
        error: action.payload,
        chartLoad: false,
      }

    case GET_CANDIDATE_DETAIL_SUCCESS:
      return {
        ...state,
        singleCandidate: action.payload,
        candidatesLoad: false,
      }

    case GET_CANDIDATE_DETAIL_FAIL:
      return {
        ...state,
        shops: action.payload,
        candidatesLoad: false,
      }

    case CREATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        singleCandidate: action.payload,
        candidatesLoad: false,
      }
    case CREATE_CANDIDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        candidatesLoad: false,
      }

    case UPDATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        candidates: action.payload,
        candidatesLoad: false,
      }
    case UPDATE_CANDIDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        candidatesLoad: false,
      }

    case UPLOAD_CANDIDATE_PIC_SUCCESS:
      return {
        ...state,
        singleCandidate: action.payload,
      }
    case UPLOAD_CANDIDATE_PIC_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPLOAD_CANDIDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        candidateDocuments: action.payload,
        singleCandidate: {
          ...state.singleCandidate,
          documents: [...state.singleCandidate.documents, action.payload],
        },
      }
    case UPDATE_CANDIDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        singleCandidate: {
          ...state.singleCandidate,
          documents: state.singleCandidate.documents?.map(i =>
            i.id === action.payload.id ? action.payload : i
          ),
        },
      }
    case DELETE_CANDIDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        singleCandidate: {
          ...state.singleCandidate,
          documents: state.singleCandidate.documents?.filter(
            i => i.id !== action.payload
          ),
        },
      }
    case UPLOAD_CANDIDATE_DOCUMENT_FAIL:
    case UPDATE_CANDIDATE_DOCUMENT_FAIL:
    case DELETE_CANDIDATE_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CANDIDATE_SUCCESS:
      return {
        ...state,
        candidates: {
          ...state.candidates,
          results: state.candidates?.results?.filter(
            candidate => candidate.id !== action.payload
          ),
        },
        candidatesLoad: false,
      }

    case DELETE_CANDIDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        candidatesLoad: false,
      }

    //agentes
    case GET_AGENTES:
    case GET_AGENTE_DETAIL:
    case CREATE_AGENTE:
    case UPDATE_AGENTE:
    case DELETE_AGENTE:
      return {
        ...state,
        agentesLoad: true,
      }
    case GET_AGENTES_SUCCESS:
      return {
        ...state,
        agentes: action.payload,
        agentesLoad: false,
      }

    case GET_AGENTES_FAIL:
      return {
        ...state,
        error: action.payload,
        agentesLoad: false,
      }
    case GET_AGENTE_DETAIL_SUCCESS:
      return {
        ...state,
        singleAgente: action.payload,
        agentesLoad: false,
      }

    case GET_AGENTE_DETAIL_FAIL:
      return {
        ...state,
        agentes: action.payload,
        agentesLoad: false,
      }

    case CREATE_AGENTE_SUCCESS:
      return {
        ...state,
        singleAgente: action.payload,
        agentesLoad: false,
      }
    case CREATE_AGENTE_FAIL:
      return {
        ...state,
        error: action.payload,
        agentesLoad: false,
      }

    case UPDATE_AGENTE_SUCCESS:
      return {
        ...state,
        agentes: [state.agentes, action.payload],
        agentesLoad: false,
      }
    case UPDATE_AGENTE_FAIL:
      return {
        ...state,
        error: action.payload,
        agentesLoad: false,
      }

    case DELETE_AGENTE_SUCCESS:
      return {
        ...state,
        agentes: {
          ...state.agentes,
          results: state.agentes?.results?.filter(
            agente => agente.id !== action.payload
          ),
        },
        agentesLoad: false,
      }

    case DELETE_AGENTE_FAIL:
      return {
        ...state,
        error: action.payload,
        agentesLoad: false,
      }

    //company
    case GET_COMPANIES:
    case GET_COMPANY_DETAIL:
    case CREATE_COMPANY:
    case UPDATE_COMPANY:
    case DELETE_COMPANY:
      return {
        ...state,
        companyLoad: true,
      }
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        company: action.payload,
        companyLoad: false,
      }

    case GET_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
        companyLoad: false,
      }
    case GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        singleCompany: action.payload,
        companyLoad: false,
      }

    case GET_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        company: action.payload,
        companyLoad: false,
      }

    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        singleCompany: action.payload,
        companyLoad: false,
      }
    case CREATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        companyLoad: false,
      }

    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        companyLoad: false,
      }
    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        companyLoad: false,
      }

    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          results: state.company?.results?.filter(
            compnay => compnay.id !== action.payload
          ),
        },
        companyLoad: false,
      }

    case DELETE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        companyLoad: false,
      }

    default:
      return state
  }
}

export default Users
