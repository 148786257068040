import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
import PropTypes from "prop-types"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
// import { getSalesmans } from "store/actions"

import "../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import { getCandidates } from "store/actions"

const Candidate = ({ isInner, candidates: singleCandidate }) => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const { loading, candidates: AllCandidates } = useSelector(state => ({
    candidates: state.Users.candidates,
    loading: state.Users.candidatesLoad,
  }))

  console.log(AllCandidates)

  const candidates = () => {
    if (singleCandidate) {
      return singleCandidate
    } else {
      return AllCandidates
    }
  }

  const [sort, setSort] = useState("")

  const listChange = sortValue => {
    setSort(sortValue)
  }

  //pages
  const totalPages = Math.ceil(candidates()?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getCandidates(searchText, pageSend(), sort))
  }, [dispatch, page, searchText, sort])

  const columns = [
    {
      dataField: "auto_id",
      text: "Sl No",
      sort: true,
    },
    {
      dataField: "given_name",
      text: "Name",
    },
    {
      dataField: "passport_no",
      text: "Passport No",
    },

    {
      dataField: "phone",
      text: "Phone",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const salesmansData = map(candidates()?.results, (item, index) => ({
    ...item,
    key: index,
    auto_id: index + 1,
    given_name: (
      <span>
        {item?.surname} {item?.given_name}
      </span>
    ),
    action: (
      <div>
        <Link to={`/single/candidate/${item?.id}`} className="btn-light btn-sm">
          View
        </Link>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }
  // /shop/make
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row>
                {isInner === true ? (
                  <></>
                ) : (
                  <Col xl={10} lg={10} md={10} sm={12} className="">
                    <div
                      className="btn-group mt-xl-0"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autoComplete="off"
                        defaultChecked
                        onChange={() => listChange("")}
                      />
                      <label className="btn btn-info" htmlFor="btnradio1">
                        All
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autoComplete="off"
                        onChange={() => listChange("new")}
                      />
                      <label className="btn btn-info" htmlFor="btnradio2">
                        New
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio3"
                        autoComplete="off"
                        onChange={() => listChange("fund_received")}
                      />
                      <label className="btn btn-info" htmlFor="btnradio3">
                        Fund received
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio4"
                        autoComplete="off"
                        onChange={() => listChange("applied_in_embassy")}
                      />
                      <label className="btn btn-info" htmlFor="btnradio4">
                        Applied in embassy
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio5"
                        autoComplete="off"
                        onChange={() => listChange("processing")}
                      />
                      <label className="btn btn-info" htmlFor="btnradio5">
                        Processing
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio6"
                        autoComplete="off"
                        onChange={() => listChange("applied_in_india")}
                      />
                      <label className="btn btn-info" htmlFor="btnradio6">
                        Applied in india
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio7"
                        autoComplete="off"
                        onChange={() => listChange("visa_approved")}
                      />
                      <label className="btn btn-info" htmlFor="btnradio7">
                        Visa approved
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio8"
                        autoComplete="off"
                        onChange={() => listChange("rejected")}
                      />
                      <label className="btn btn-info" htmlFor="btnradio8">
                        Rejected
                      </label>
                    </div>
                  </Col>
                )}
                {isInner === true ? (
                  <></>
                ) : (
                  <Col className="col-2 d-flex align-items-center justify-content-end">
                    <Link to="/make/candidate">
                      <Button type="submit" color="success" className="w-md">
                        Create
                      </Button>
                    </Link>
                  </Col>
                )}
              </Row>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={salesmansData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    {isInner === true ? (
                      <></>
                    ) : (
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <form
                                className="app-search d-lg-block"
                                onChange={e => handleSearch(e)}
                              >
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    defaultValue={searchText}
                                  />
                                  <span className="bx bx-search-alt" />
                                </div>
                              </form>
                            </div>
                          </div>
                        </Col>
                        <Col md="4"></Col>
                      </Row>
                    )}
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={item => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          onFastNextClick={() => setPage(pages.length)}
                          onFastPrevClick={() => setPage(1)}
                          apiPage={pageSend}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Candidate

Candidate.propTypes = {
  isInner: PropTypes.bool,
  candidates: PropTypes.any,
}
