import MetaTag from "constants/Meta-tag"
import React from "react"
import { Container } from "reactstrap"
import DatatableTables from "./DatatableTables"
import Breadcrumbs from "components/Common/Breadcrumb"

function AccountByCategory() {
  return (
    <>
      <MetaTag title={"All Accounts"} />

      <div className="page-content">
        <Breadcrumbs title="Accounts" breadcrumbItem="Accounts by category" />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables isAccountByCat={true} />
          </div>
        </Container>
      </div>
    </>
  )
}

export default AccountByCategory
