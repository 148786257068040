function sidebardata() {
  return [
    {
      heading: "Candidates",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "fas fa-users",
      subTitles: [],
      routeLink: "/candidates",
      dropDown: false,
      subTitles: [
        {
          title: "New Candidate",
          routeLink: "#!",
        },
        {
          title: "Checked Candidate",
          routeLink: "#!",
        },
        {
          title: "Pending Candidate",
          routeLink: "#!",
        },
      ],
    },
    {
      heading: "Transations",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "fas fa-university",
      subTitles: [],
      routeLink: "/transations",
    },

    {
      heading: "Category Report",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bx-spreadsheet",
      subTitles: [],
      routeLink: "/account/category",
    },

    {
      heading: "Accounts",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "fas fa-shield-alt",
      subTitles: [],
      routeLink: "/accounts",
    },

    {
      heading: "",
      headingSaparetor: "Setup",
      badgeValue: "",
      iconClass: "",
      subTitles: [],
      routeLink: "",
    },
    {
      heading: "Agents",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "fas fa-user-tie",
      subTitles: [],
      routeLink: "/agents",
    },

    {
      heading: "Companies",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "fas fa-building",
      subTitles: [],
      routeLink: "/companies",
    },

    {
      heading: "Account Category",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "bx bxs-check-shield",
      subTitles: [],
      routeLink: "/account/categories",
    },
  ]
}

export const mySideBar = sidebardata()

//Prod
