import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
// import { getSalesmans } from "store/actions"

import "../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import { deleteAgente, getAgentes, showDeletePopup } from "store/actions"
import DeleteModal from "components/Common/DeleteModal"

const Agente = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const { candidates, loading } = useSelector(state => ({
    candidates: state.Users.agentes,
    loading: state.Users.agentesLoad,
  }))

  //pages
  const totalPages = Math.ceil(candidates?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getAgentes(searchText, pageSend()))
  }, [dispatch, page, searchText])

  const [itemId, setItemId] = useState()
  const handleDelete = id => {
    setItemId(id)
    dispatch(showDeletePopup())
  }
  const onDeleteFun = () => {
    if (itemId) {
      dispatch(deleteAgente(itemId))
    }
  }

  const columns = [
    {
      dataField: "auto_id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "agent_name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "contact",
      text: "Phone",
    },
    {
      dataField: "country",
      text: "Country",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const salesmansData = map(candidates?.results, (item, index) => ({
    ...item,
    key: index,
    action: (
      <div className="d-flex align-items-center">
        <Link to={`/renew/agents/${item?.id}`} className="btn-light btn-sm">
          View
        </Link>
        <i
          onClick={() => {
            handleDelete(item.id)
          }}
          className="bx bx-trash mx-1"
          style={{ fontSize: "16px", cursor: "pointer" }}
        />
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }
  // /shop/make
  return (
    <React.Fragment>
      <DeleteModal onDeleteClick={onDeleteFun} />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={salesmansData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                      <Col md="4"></Col>
                      <Col
                        md="4"
                        className="d-flex align-items-center justify-content-end"
                      >
                        <Link to="/make/agents">
                          <Button
                            type="submit"
                            color="success"
                            className="w-md"
                          >
                            Create New Agent
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={item => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          onFastNextClick={() => setPage(pages.length)}
                          onFastPrevClick={() => setPage(1)}
                          apiPage={pageSend}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Agente
