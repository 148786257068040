// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  SHOW_ACCOUNT_CATEGORY,
  HIDE_ACCOUNT_CATEGORY,
  SHOW_DELETE_POPUP,
  HIDE_DELETE_POPUP,
  SHOW_ACCOUNT_POPUP,
  HIDE_ACCOUNT_POPUP,
} from "./actionTypes"

//constants
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout"

const INIT_STATE = {
  layoutType: layoutTypes.VERTICAL,
  layoutModeType: "DARK",
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.LIGHT,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  isAccCategory: false,
  isDeletePopup: false,
  isAccountPopup: false,
  accCatId: ''
}

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_THEME_IMAGE:
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      }

    case SHOW_ACCOUNT_CATEGORY:
      return {
        ...state,
        isAccCategory: true,
        accCatId: action.payload
      }
    case HIDE_ACCOUNT_CATEGORY:
      return {
        ...state,
        isAccCategory: false,
        accCatId: ''
      }

    case SHOW_DELETE_POPUP:
      return {
        ...state,
        isDeletePopup: true,
      }
    case HIDE_DELETE_POPUP:
      return {
        ...state,
        isDeletePopup: false,
      }

    case SHOW_ACCOUNT_POPUP:
      return {
        ...state,
        isAccountPopup: true,
      }
    case HIDE_ACCOUNT_POPUP:
      return {
        ...state,
        isAccountPopup: false,
      }
    default:
      return state
  }
}

export default Layout
