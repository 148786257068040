import React from "react"
import ReactApexChart from "react-apexcharts"
import randomColor from "randomcolor"
import PropTypes from "prop-types"

const PieChart = ({ chartData, loading }) => {
  console.log(chartData)
  const labels = chartData && Object.keys(chartData)
  const seriesItems = chartData && Object.values(chartData)

  const series = seriesItems?.map(i => (i === null ? 0 : i))

  const count = labels ? labels?.length : 0

  var color = randomColor({
    count: count,
    luminosity: "dark",
    hue: "",
  })
  // const allColor = [
  //   "#0E6251",
  //   "#0B5345 ",
  //   "#145A32",
  //   "#186A3B",
  //   "#117864",
  //   "#0E6655",
  //   "#196F3D",
  //   "#1D8348",
  // ]

  const options = {
    labels: labels ? labels : [],
    colors: color,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: 0,
    },

    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  return (
    <div className="custom_chart">
      {loading ? (
        <p>loading...</p>
      ) : (
        <>
          {series !== undefined && (
            <ReactApexChart
              options={options}
              series={series}
              type="pie"
              height={350}
            />
          )}
        </>
      )}
    </div>
  )
}

export default PieChart
PieChart.propTypes = {
  chartData: PropTypes.any,
  loading: PropTypes.bool,
  type: PropTypes.any,
}
