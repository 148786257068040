import MetaTag from "constants/Meta-tag"
import React from "react"

import Breadcrumbs from "components/Common/Breadcrumb"
import AccountChart from "./comp/AccountChart"

function Accounts() {
  return (
    <>
      <MetaTag title={"All Accounts"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Accounts" />

        <AccountChart />
      </div>
    </>
  )
}

export default Accounts
