import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Modal, ModalBody, Row } from "reactstrap"
import { createAccountCategory, getAccountCategoryDetail, hideAccountCategory, updateAccountCategory } from "store/actions"

export const transType = ["credit", "debit"]

const AccCatModal = () => {
  const dispatch = useDispatch()
  const { isAccCategory, loading, error, accCatId, accountCategoryDetail } = useSelector(state => ({
    isAccCategory: state.Layout.isAccCategory,
    loading: state.Accounts.accountCategoryLoad,
    error: state.Accounts.error,
    accCatId: state.Layout.accCatId,
    accountCategoryDetail: state.Accounts.accountCategoryDetail
  }))

  const onCloseClick = () => {
    dispatch(hideAccountCategory())
  }

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    if (accCatId) {
      dispatch(updateAccountCategory(values, accCatId))
    } else {
      dispatch(createAccountCategory(values))
    }
  }

  useEffect(() => {
    if (accCatId) {
      dispatch(getAccountCategoryDetail(accCatId))
    }
  }, [dispatch, accCatId])




  return (
    <Modal isOpen={isAccCategory} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-4 px-5">
        <Row>
          <Col lg={12}>
            <AvForm
              className="form-horizontal "
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              { }
              <div className="row mb-4">
                <Col className="col-6">
                  <AvField
                    label={"Category Name"}
                    id="horizontal-username-Input"
                    className="filePathClass"
                    name="category_name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    value={accCatId ? accountCategoryDetail?.category_name : ""}
                  />
                </Col>
                <Col className="col-6">
                  <AvField
                    label={"Transaction Type"}
                    type="select"
                    name="transaction_type"
                    className="text-muted"
                    style={{ textTransform: "capitalize" }}
                    value={accCatId ? accountCategoryDetail?.transaction_type : ""}
                  >
                    <option>Select...</option>

                    {transType?.map((item, key) => (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    ))}
                  </AvField>
                </Col>
              </div>

              <div className="row justify-content-end mt-5">
                <Col>
                  <div className="d-flex justify-content-end">
                    <Button
                      type="button"
                      color="light"
                      className="w-md mx-3"
                      onClick={onCloseClick}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="success" className="w-md">
                      {loading && (
                        <>
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        </>
                      )}
                      {accCatId ? "Update" : "Create"}
                      &nbsp;Account Category
                    </Button>
                  </div>
                </Col>
              </div>
            </AvForm>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

AccCatModal.propTypes = {}

export default AccCatModal
