import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { CardBody, Spinner } from "reactstrap"

//actions
import { logoutUser } from "../../store/actions"

//redux
import { useDispatch } from "react-redux"

const Logout = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logoutUser(props.history))
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="container">
        <CardBody className="d-flex" style={{ height: "100vh" }}>
          <Spinner className="d-block m-auto" />
        </CardBody>
      </div>
    </React.Fragment>
  )
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
