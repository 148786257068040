import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"
import DatatableTables2 from "./DatatableTables2"
import PieChart from "pages/Dashboard/charts/pieChart"
import { useDispatch, useSelector } from "react-redux"
import { getCandidatePiachart, showAccountPopup } from "store/actions"
import moment from "moment"

function AccountChart() {
  const dispatch = useDispatch()
  const { accountIncome, accountExpense, chartLoad, candidateChart } =
    useSelector(state => ({
      accountIncome: state.Accounts.accountIncome,
      accountExpense: state.Accounts.accountExpense,
      chartLoad: state.Users.chartLoad,
      candidateChart: state.Users.candidateChart,
    }))

  const currentYear = new Date().getFullYear()
  const firstDay = new Date(currentYear, 0, 1)
  const lastDay = new Date(currentYear, 11, 31)

  const [date, setDate] = useState({
    start_date: moment(firstDay).format("YYYY-MM-DD"),
    end_date: moment(lastDay).format("YYYY-MM-DD"),
  })

  const openAccountModal = () => {
    dispatch(showAccountPopup())
  }

  useEffect(() => {
    if (date) {
      dispatch(getCandidatePiachart("", "all", date))
    } else {
      dispatch(getCandidatePiachart("", "all"))
    }
  }, [dispatch, date])

  const income = candidateChart?.income
    ? Object.values(candidateChart?.income)
    : []
  const expense = candidateChart?.expense
    ? Object.values(candidateChart?.expense)
    : []

  const totalIncome = income?.reduce((a, b) => a + b, 0)
  const totalExpence = expense?.reduce((a, b) => a + b, 0)
  const totalProfit = totalIncome - totalExpence

  const profitChartData = {
    Income: totalIncome,
    Expense: totalExpence,
    Profit: totalProfit,
  }

  return (
    <Container fluid>
      <div className="">
        <Card>
          <CardBody>
            <CardTitle>Account chart</CardTitle>
            <Row className="mb-5">
              <div className="col-6"></div>
              <div className="col-6 d-flex align-items-center">
                <label className="mx-3 mb-0" style={{ whiteSpace: "nowrap" }}>
                  Filter by date
                </label>
                <form className="d-flex">
                  <input
                    name="start_date"
                    className="form-control"
                    type="date"
                    // defaultValue="2019-08-19"
                    id="example-date-input"
                    onChange={e =>
                      setDate({
                        ...date,
                        start_date: moment(e.target.value).format("YYYY-MM-DD"),
                      })
                    }
                    value={date.start_date}
                  />
                  <p>&nbsp;&nbsp;</p>
                  <input
                    name="end_date"
                    className="form-control"
                    type="date"
                    // defaultValue="2019-08-19"
                    id="example-date-input"
                    onChange={e =>
                      setDate({
                        ...date,
                        end_date: moment(e.target.value).format("YYYY-MM-DD"),
                      })
                    }
                    value={date.end_date}
                  />
                </form>
              </div>
            </Row>
            <Row>
              <Col className="col-4 text-center">
                <CardTitle>Income</CardTitle>
                <PieChart
                  chartData={candidateChart?.income}
                  loading={chartLoad}
                />
                <CardTitle className="mt-2">
                  {totalIncome} <span className="font-size-12"> /-</span>
                </CardTitle>
              </Col>
              <Col className="col-4 text-center">
                <CardTitle>Expence</CardTitle>
                <PieChart
                  chartData={candidateChart?.expense}
                  loading={chartLoad}
                />
                <CardTitle className="mt-2">
                  {totalExpence} <span className="font-size-12"> /-</span>
                </CardTitle>
              </Col>
              <Col className="col-4 text-center">
                <CardTitle>Profit</CardTitle>
                <PieChart chartData={profitChartData} loading={chartLoad} />
                <CardTitle
                  className={`mt-2 ${
                    Math.sign(totalProfit) === 1 ? "text-green" : "text-danger"
                  }`}
                >
                  {totalProfit} <span className="font-size-12"> /-</span>
                </CardTitle>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <div className="">
        <Card>
          <CardBody>
            <Row>
              <Col xl={9} lg={9} md={9} sm={6}></Col>
              {/* <Col xl={3} lg={3} md={3} sm={6}>
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn mb-2 me-2"
                    onClick={openAccountModal}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Create New Entry
                  </Button>
                </div>
              </Col> */}
              <Col className="col-6">
                <CardTitle>Income</CardTitle>
                <DatatableTables2
                  date={date}
                  sort={"income"}
                  accounts={accountIncome}
                />
              </Col>
              <Col className="col-6">
                <CardTitle>Expence</CardTitle>
                <DatatableTables2
                  date={date}
                  sort={"expense"}
                  accounts={accountExpense}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      {/* <div className="">
    <DatatableTables />
  </div> */}
    </Container>
  )
}

export default AccountChart
