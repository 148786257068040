import PropTypes from "prop-types"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { hideDeletePopup } from "store/actions"

const DeleteModal = ({ onDeleteClick }) => {
  const dispatch = useDispatch()
  const { isDeletePopup } = useSelector(state => ({
    isDeletePopup: state.Layout.isDeletePopup,
  }))

  const onCloseClick = () => {
    dispatch(hideDeletePopup())
  }

  return (
    <Modal isOpen={isDeletePopup} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "5em", color: "orange" }}
              />
              <h3>Are you sure?</h3>
              <h4 className="font-size-14">
                {"You won't be able to revert this!"}
              </h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={onDeleteClick}
              >
                Confirm!
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default DeleteModal
