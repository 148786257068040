import {
  CREATE_ACCOUNTS,
  CREATE_ACCOUNTS_FAIL,
  CREATE_ACCOUNTS_SUCCESS,
  CREATE_ACCOUNT_CATEGORY,
  CREATE_ACCOUNT_CATEGORY_FAIL,
  CREATE_ACCOUNT_CATEGORY_SUCCESS,
  DELETE_ACCOUNTS,
  DELETE_ACCOUNTS_FAIL,
  DELETE_ACCOUNTS_SUCCESS,
  DELETE_ACCOUNT_CATEGORY,
  DELETE_ACCOUNT_CATEGORY_FAIL,
  DELETE_ACCOUNT_CATEGORY_SUCCESS,
  GET_ACCOUNTS,
  GET_ACCOUNTS_DETAIL,
  GET_ACCOUNTS_DETAIL_FAIL,
  GET_ACCOUNTS_DETAIL_SUCCESS,
  GET_ACCOUNTS_EXPENSE_SUCCESS,
  GET_ACCOUNTS_FAIL,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNT_CATEGORY,
  GET_ACCOUNT_CATEGORY_DETAIL,
  GET_ACCOUNT_CATEGORY_DETAIL_FAIL,
  GET_ACCOUNT_CATEGORY_DETAIL_SUCCESS,
  GET_ACCOUNT_CATEGORY_FAIL,
  GET_ACCOUNT_CATEGORY_SUCCESS,
  GET_ACCOUNT_INCOME_SUCCESS,
  UPDATE_ACCOUNTS,
  UPDATE_ACCOUNTS_FAIL,
  UPDATE_ACCOUNTS_SUCCESS,
  UPDATE_ACCOUNT_CATEGORY,
  UPDATE_ACCOUNT_CATEGORY_FAIL,
  UPDATE_ACCOUNT_CATEGORY_SUCCESS,
} from "./actionTypes"

export const getAccounts = (
  searchText,
  page,
  sort,
  candidateId,
  catId,
  date
) => ({
  type: GET_ACCOUNTS,
  payload: { searchText, page, sort, candidateId, catId, date },
})

export const getAccountsSuccess = accounts => ({
  type: GET_ACCOUNTS_SUCCESS,
  payload: accounts,
})
export const getAccountIncomeSuccess = accounts => ({
  type: GET_ACCOUNT_INCOME_SUCCESS,
  payload: accounts,
})
export const getAccountExpenseSuccess = accounts => ({
  type: GET_ACCOUNTS_EXPENSE_SUCCESS,
  payload: accounts,
})
export const getAccountsFail = error => ({
  type: GET_ACCOUNTS_FAIL,
  payload: error,
})

export const getAccountDetail = accId => ({
  type: GET_ACCOUNTS_DETAIL,
  accId,
})

export const getAccountDetailSuccess = accountDetails => ({
  type: GET_ACCOUNTS_DETAIL_SUCCESS,
  payload: accountDetails,
})

export const getAccountDetailFail = error => ({
  type: GET_ACCOUNTS_DETAIL_FAIL,
  payload: error,
})

export const createAccount = accounts => ({
  type: CREATE_ACCOUNTS,
  payload: { accounts },
})

export const createAccountSuccess = accounts => ({
  type: CREATE_ACCOUNTS_SUCCESS,
  payload: accounts,
})

export const createAccountFail = error => ({
  type: CREATE_ACCOUNTS_FAIL,
  payload: error,
})

export const updateAccount = (accounts, accId, history) => ({
  type: UPDATE_ACCOUNTS,
  payload: { accounts, accId, history },
})

export const updateAccountSuccess = accounts => ({
  type: UPDATE_ACCOUNTS_SUCCESS,
  payload: accounts,
})

export const updateAccountFail = error => ({
  type: UPDATE_ACCOUNTS_FAIL,
  payload: error,
})

export const deleteAccount = (accId, history) => ({
  type: DELETE_ACCOUNTS,
  payload: { accId, history },
})

export const deleteAccountSuccess = accounts => ({
  type: DELETE_ACCOUNTS_SUCCESS,
  payload: accounts,
})

export const deleteAccountFail = error => ({
  type: DELETE_ACCOUNTS_FAIL,
  payload: error,
})

//account category
export const getAccountCategory = (searchText, page, sort, date) => ({
  type: GET_ACCOUNT_CATEGORY,
  payload: { searchText, page, sort, date },
})

export const getAccountCategorySuccess = accCat => ({
  type: GET_ACCOUNT_CATEGORY_SUCCESS,
  payload: accCat,
})
export const getAccountCategoryFail = error => ({
  type: GET_ACCOUNT_CATEGORY_FAIL,
  payload: error,
})

export const getAccountCategoryDetail = accCatId => ({
  type: GET_ACCOUNT_CATEGORY_DETAIL,
  accCatId,
})

export const getAccountCategoryDetailSuccess = accCatDetail => ({
  type: GET_ACCOUNT_CATEGORY_DETAIL_SUCCESS,
  payload: accCatDetail,
})

export const getAccountCategoryDetailFail = error => ({
  type: GET_ACCOUNT_CATEGORY_DETAIL_FAIL,
  payload: error,
})

export const createAccountCategory = (accCat, history) => ({
  type: CREATE_ACCOUNT_CATEGORY,
  payload: { accCat, history },
})

export const createAccountCategorySuccess = accCat => ({
  type: CREATE_ACCOUNT_CATEGORY_SUCCESS,
  payload: accCat,
})

export const createAccountCategoryFail = error => ({
  type: CREATE_ACCOUNT_CATEGORY_FAIL,
  payload: error,
})

export const updateAccountCategory = (accCat, accCatId) => ({
  type: UPDATE_ACCOUNT_CATEGORY,
  payload: { accCat, accCatId },
})

export const updateAccountCategorySuccess = accCat => ({
  type: UPDATE_ACCOUNT_CATEGORY_SUCCESS,
  payload: accCat,
})

export const updateAccountCategoryFail = error => ({
  type: UPDATE_ACCOUNT_CATEGORY_FAIL,
  payload: error,
})

export const deleteAccountCategory = (accCatId, history) => ({
  type: DELETE_ACCOUNT_CATEGORY,
  payload: { accCatId, history },
})

export const deleteAccountCategorySuccess = accCat => ({
  type: DELETE_ACCOUNT_CATEGORY_SUCCESS,
  payload: accCat,
})

export const deleteAccountCategoryFail = error => ({
  type: DELETE_ACCOUNT_CATEGORY_FAIL,
  payload: error,
})
