import React from "react"
import { Redirect } from "react-router-dom"

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Pages500 from "pages/404/pages-500"
import Pages404 from "pages/404/pages-404"

import ForgetPwd from "pages/Authentication/ForgetPwd"
import Candidates from "pages/candidates"
import CreateCandidate from "pages/candidates/Crud/Create"
import CandidateDetails from "pages/candidates/SingleView"
import UpdateCandidate from "pages/candidates/Crud/Update"
import Agentes from "pages/agent"
import CreateAgent from "pages/agent/Crud/Create"
import UpdateAgent from "pages/agent/Crud/Update"
import Companies from "pages/companies"
import CreateCompany from "pages/companies/Crud/Create"
import UpdateCompany from "pages/companies/Crud/Update"
import Accounts from "pages/account"
import UpdateAccount from "pages/account/Crud/Update"
import AccountCategories from "pages/AccountCategory"
import Transations from "pages/transations"
import AccountByCategory from "pages/account/FilterByCategory"

const commonRoute = [
  { path: "/dashboard", component: Dashboard },
  //candidate
  { path: "/candidates", component: Candidates },
  { path: "/make/candidate", component: CreateCandidate },
  { path: "/renew/candidate/:id", component: UpdateCandidate },
  { path: "/single/candidate/:id", component: CandidateDetails },

  //agentes
  { path: "/agents", component: Agentes },
  { path: "/make/agents", component: CreateAgent },
  { path: "/renew/agents/:id", component: UpdateAgent },

  //companies
  { path: "/companies", component: Companies },
  { path: "/make/companies", component: CreateCompany },
  { path: "/renew/companies/:id", component: UpdateCompany },

  //accounts
  { path: "/accounts", component: Accounts },
  { path: "/renew/account/:id", component: UpdateAccount },
  { path: "/account/category/:id", component: AccountByCategory },
  { path: "/account/category", component: AccountByCategory },

  { path: "/account/categories", component: AccountCategories },

  { path: "/transations", component: Transations },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
]

export { publicRoutes, commonRoute }

//pendings candidate account category transation
