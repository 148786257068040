import { call, put, takeEvery } from "redux-saga/effects"

import { get, post, ApiPut, del, patch } from "helpers/api_methods"
import {
  createAccountCategoryFail,
  createAccountCategorySuccess,
  createAccountFail,
  createAccountSuccess,
  deleteAccountCategoryFail,
  deleteAccountCategorySuccess,
  deleteAccountFail,
  deleteAccountSuccess,
  getAccountCategoryDetailFail,
  getAccountCategoryDetailSuccess,
  getAccountCategoryFail,
  getAccountCategorySuccess,
  getAccountDetailFail,
  getAccountDetailSuccess,
  getAccountExpenseSuccess,
  getAccountIncomeSuccess,
  getAccountsFail,
  getAccountsSuccess,
  updateAccountCategoryFail,
  updateAccountCategorySuccess,
  updateAccountFail,
  updateAccountSuccess,
} from "./actions"
import { doneNotification, errorNotification } from "constants/notification"
import {
  CREATE_ACCOUNTS,
  CREATE_ACCOUNT_CATEGORY,
  DELETE_ACCOUNTS,
  DELETE_ACCOUNT_CATEGORY,
  GET_ACCOUNTS,
  GET_ACCOUNTS_DETAIL,
  GET_ACCOUNT_CATEGORY,
  GET_ACCOUNT_CATEGORY_DETAIL,
  UPDATE_ACCOUNTS,
  UPDATE_ACCOUNT_CATEGORY,
} from "./actionTypes"
import { hideAccountCategory } from "store/actions"
import { hideDeletePopup } from "store/actions"
import { hideAccountPopup } from "store/actions"

const middleWareUrl = "/api/v1"

//Account
const getAccountAPi = ({
  searchText,
  page,
  sort,
  candidateId,
  catId,
  date,
}) => {
  if (candidateId) {
    return get(
      `${middleWareUrl}/account/account/?page=${page ? page : 1}&type=${sort && sort
      }&candidate=${candidateId}&search=${searchText && searchText}`
    )
  } else if (catId) {
    return get(
      `${middleWareUrl}/account/account/?page=${page ? page : 1}&type=${sort && sort
      }&category=${catId}`
    )
  } else if (date.start_date !== undefined && date.end_date !== undefined) {
    return get(
      `${middleWareUrl}/account/account/?page=${page ? page : 1}&type=${sort && sort
      }&search=${searchText && searchText}&start_date=${date.start_date && date.start_date
      }&end_date=${date.end_date && date.end_date}`
    )
  } else {
    return get(
      `${middleWareUrl}/account/account/?page=${page ? page : 1}&type=${sort && sort
      }&search=${searchText && searchText}`
    )
  }
}
const getAccountDetailsAPi = accId => {
  return get(`${middleWareUrl}/account/account/${accId}`)
}
const createAccountApi = ({ accounts }) => {
  return post(`${middleWareUrl}/account/account/`, accounts)
}
const updateAccountApi = ({ accounts, accId }) => {
  return ApiPut(`${middleWareUrl}/account/account/${accId}/`, accounts)
}
const deleteAccountApi = ({ accId }) => {
  return del(`${middleWareUrl}/account/account/${accId}/`)
}

function* fetchAccounts({ payload }) {
  try {
    const response = yield call(getAccountAPi, payload)
    yield put(getAccountsSuccess(response))
  } catch (error) {
    yield put(getAccountsFail(error))
  }
}
function* fetchAccountDetail({ accId }) {
  try {
    const response = yield call(getAccountDetailsAPi, accId)
    yield put(getAccountDetailSuccess(response))
  } catch (error) {
    yield put(getAccountDetailFail(error))
  }
}
function* onCreateAccounts({ payload }) {
  try {
    const response = yield call(createAccountApi, payload)
    if (response?.id) {
      yield put(createAccountSuccess(response))
      doneNotification("Account Created Success")
      yield put(hideAccountPopup())
    } else {
      yield put(createAccountFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(createAccountFail(error))
    errorNotification()
  }
}

function* onUpdateAccounts({ payload }) {
  try {
    const response = yield call(updateAccountApi, payload)
    if (response?.id) {
      yield put(updateAccountSuccess(response))
      doneNotification()
      payload.history.goBack()
    } else {
      yield put(updateAccountFail(response))
    }
  } catch (error) {
    yield put(updateAccountFail(error))
    errorNotification()
  }
}

function* onDeleteAccount({ payload }) {
  try {
    const response = yield call(deleteAccountApi, payload)
    if (response) {
      yield put(deleteAccountSuccess(payload.accId))
      doneNotification()
      yield put(hideDeletePopup())
    }
  } catch (error) {
    console.log(error)
    yield put(deleteAccountFail(error))
    errorNotification()
  }
}

//account category
const getAccountCategoryAPi = ({ searchText, page, sort, date }) => {
  console.log(sort)
  if (date) {
    return get(
      `${middleWareUrl}/account/account_category/?page=${page ? page : 1
      }&type=${sort ? sort : ""}&start_date=${date.start_date}&end_date=${date.end_date
      }`
    )
  } else if (searchText) {
    return get(
      `${middleWareUrl}/account/account_category/?search=${searchText && searchText
      }`
    )
  } else {
    return get(
      `${middleWareUrl}/account/account_category/?page=${page ? page : 1
      }&type=${sort ? sort : ""}`
    )
  }
}
const getAccountCategoryDetailsAPi = accCatId => {
  return get(`${middleWareUrl}/account/account_category/${accCatId}`)
}
const createAccountCategoryApi = ({ accCat }) => {
  return post(`${middleWareUrl}/account/account_category/`, accCat)
}
const updateAccountCategoryApi = ({ accCat, accCatId }) => {
  return ApiPut(
    `${middleWareUrl}/account/account_category/${accCatId}/`,
    accCat
  )
}
const deleteAccountCategoryApi = ({ accCatId }) => {
  return del(`${middleWareUrl}/account/account_category/${accCatId}/`)
}

function* fetchAccountCategory({ payload }) {
  try {
    const response = yield call(getAccountCategoryAPi, payload)
    if (payload?.sort === "income") {
      yield put(getAccountIncomeSuccess(response))
    }
    if (payload?.sort === "expense") {
      yield put(getAccountExpenseSuccess(response))
    }
    yield put(getAccountCategorySuccess(response))
  } catch (error) {
    yield put(getAccountCategoryFail(error))
  }
}
function* fetchAccountCategoryDetail({ accCatId }) {
  try {
    const response = yield call(getAccountCategoryDetailsAPi, accCatId)
    yield put(getAccountCategoryDetailSuccess(response))
  } catch (error) {
    yield put(getAccountCategoryDetailFail(error))
  }
}
function* onCreateAccountCategory({ payload }) {
  try {
    const response = yield call(createAccountCategoryApi, payload)
    if (response?.id) {
      yield put(createAccountCategorySuccess(response))
      doneNotification("Category Created Success")
      yield put(hideAccountCategory())
    } else {
      yield put(createAccountCategoryFail(response))
    }
  } catch (error) {
    yield put(createAccountCategoryFail(error))
    errorNotification()
  }
}

function* onUpdateAccountCategory({ payload }) {
  try {
    const response = yield call(updateAccountCategoryApi, payload)
    if (response?.id) {
      yield put(updateAccountCategorySuccess(response))
      doneNotification("Category Updated Successfully")
      yield put(hideAccountCategory())
    } else {
      yield put(updateAccountCategoryFail(response))
    }
  } catch (error) {
    yield put(updateAccountCategoryFail(error))
    errorNotification()
  }
}

function* onDeleteAccountCategory({ payload }) {
  try {
    const response = yield call(deleteAccountCategoryApi, payload)
    if (response) {
      yield put(deleteAccountCategorySuccess(payload.accCatId))
      doneNotification()
      yield put(hideDeletePopup())
    }
  } catch (error) {
    yield put(deleteAccountCategoryFail(error))
    errorNotification()
  }
}

function* accountSaga() {
  yield takeEvery(GET_ACCOUNTS, fetchAccounts)
  yield takeEvery(GET_ACCOUNTS_DETAIL, fetchAccountDetail)
  yield takeEvery(CREATE_ACCOUNTS, onCreateAccounts)
  yield takeEvery(UPDATE_ACCOUNTS, onUpdateAccounts)
  yield takeEvery(DELETE_ACCOUNTS, onDeleteAccount)

  yield takeEvery(GET_ACCOUNT_CATEGORY, fetchAccountCategory)
  yield takeEvery(GET_ACCOUNT_CATEGORY_DETAIL, fetchAccountCategoryDetail)
  yield takeEvery(CREATE_ACCOUNT_CATEGORY, onCreateAccountCategory)
  yield takeEvery(UPDATE_ACCOUNT_CATEGORY, onUpdateAccountCategory)
  yield takeEvery(DELETE_ACCOUNT_CATEGORY, onDeleteAccountCategory)
}

export default accountSaga
