export const GET_ACCOUNTS = "GET_ACCOUNTS"
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS"
export const GET_ACCOUNTS_FAIL = "GET_ACCOUNTS_FAIL"

export const GET_ACCOUNT_INCOME_SUCCESS = "GET_ACCOUNT_INCOME_SUCCESS"
export const GET_ACCOUNTS_EXPENSE_SUCCESS = "GET_ACCOUNTS_EXPENSE_SUCCESS"

export const GET_ACCOUNTS_DETAIL = "GET_ACCOUNTS_DETAIL"
export const GET_ACCOUNTS_DETAIL_SUCCESS = "GET_ACCOUNTS_DETAIL_SUCCESS"
export const GET_ACCOUNTS_DETAIL_FAIL = "GET_ACCOUNTS_DETAIL_FAIL"

export const CREATE_ACCOUNTS = "CREATE_ACCOUNTS"
export const CREATE_ACCOUNTS_SUCCESS = "CREATE_ACCOUNTS_SUCCESS"
export const CREATE_ACCOUNTS_FAIL = "CREATE_ACCOUNTS_FAIL"

export const UPDATE_ACCOUNTS = "UPDATE_ACCOUNTS"
export const UPDATE_ACCOUNTS_SUCCESS = "UPDATE_ACCOUNTS_SUCCESS"
export const UPDATE_ACCOUNTS_FAIL = "UPDATE_ACCOUNTS_FAIL"

export const DELETE_ACCOUNTS = "DELETE_ACCOUNTS"
export const DELETE_ACCOUNTS_SUCCESS = "DELETE_ACCOUNTS_SUCCESS"
export const DELETE_ACCOUNTS_FAIL = "DELETE_ACCOUNTS_FAIL"

export const GET_ACCOUNT_CATEGORY = "GET_ACCOUNT_CATEGORY"
export const GET_ACCOUNT_CATEGORY_SUCCESS = "GET_ACCOUNT_CATEGORY_SUCCESS"
export const GET_ACCOUNT_CATEGORY_FAIL = "GET_ACCOUNT_CATEGORY_FAIL"

export const GET_ACCOUNT_CATEGORY_DETAIL = "GET_ACCOUNT_CATEGORY_DETAIL"
export const GET_ACCOUNT_CATEGORY_DETAIL_SUCCESS =
  "GET_ACCOUNT_CATEGORY_DETAIL_SUCCESS"
export const GET_ACCOUNT_CATEGORY_DETAIL_FAIL =
  "GET_ACCOUNT_CATEGORY_DETAIL_FAIL"

export const CREATE_ACCOUNT_CATEGORY = "CREATE_ACCOUNT_CATEGORY"
export const CREATE_ACCOUNT_CATEGORY_SUCCESS = "CREATE_ACCOUNT_CATEGORY_SUCCESS"
export const CREATE_ACCOUNT_CATEGORY_FAIL = "CREATE_ACCOUNT_CATEGORY_FAIL"

export const UPDATE_ACCOUNT_CATEGORY = "UPDATE_ACCOUNT_CATEGORY"
export const UPDATE_ACCOUNT_CATEGORY_SUCCESS = "UPDATE_ACCOUNT_CATEGORY_SUCCESS"
export const UPDATE_ACCOUNT_CATEGORY_FAIL = "UPDATE_ACCOUNT_CATEGORY_FAIL"

export const DELETE_ACCOUNT_CATEGORY = "DELETE_ACCOUNT_CATEGORY"
export const DELETE_ACCOUNT_CATEGORY_SUCCESS = "DELETE_ACCOUNT_CATEGORY_SUCCESS"
export const DELETE_ACCOUNT_CATEGORY_FAIL = "DELETE_ACCOUNT_CATEGORY_FAIL"
