import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
//users
import Users from "./users/reducer"

//account
import Accounts from "./accounts/reducer"

//dashboard
import Dashboard from "./Dashboard/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Users,
  Accounts,
  Dashboard,
})

export default rootReducer
