import {
  CREATE_CANDIDATE,
  CREATE_CANDIDATE_FAIL,
  CREATE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE,
  DELETE_CANDIDATE_FAIL,
  DELETE_CANDIDATE_SUCCESS,
  GET_CANDIDATES,
  GET_CANDIDATES_FAIL,
  GET_CANDIDATES_SUCCESS,
  GET_CANDIDATE_DETAIL,
  GET_CANDIDATE_DETAIL_FAIL,
  GET_CANDIDATE_DETAIL_SUCCESS,
  UPDATE_CANDIDATE,
  UPDATE_CANDIDATE_FAIL,
  UPDATE_CANDIDATE_SUCCESS,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL_FAIL,
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  GET_AGENTES,
  GET_AGENTES_SUCCESS,
  GET_AGENTES_FAIL,
  GET_AGENTE_DETAIL,
  GET_AGENTE_DETAIL_SUCCESS,
  GET_AGENTE_DETAIL_FAIL,
  CREATE_AGENTE,
  CREATE_AGENTE_SUCCESS,
  CREATE_AGENTE_FAIL,
  UPDATE_AGENTE,
  UPDATE_AGENTE_SUCCESS,
  UPDATE_AGENTE_FAIL,
  DELETE_AGENTE,
  DELETE_AGENTE_SUCCESS,
  DELETE_AGENTE_FAIL,
  GET_CANDIDATE_PIACHART,
  GET_CANDIDATE_PIACHART_SUCCESS,
  GET_CANDIDATE_PIACHART_FAIL,
  UPLOAD_CANDIDATE_PIC,
  UPLOAD_CANDIDATE_PIC_SUCCESS,
  UPLOAD_CANDIDATE_PIC_FAIL,
  UPLOAD_CANDIDATE_DOCUMENT,
  UPLOAD_CANDIDATE_DOCUMENT_FAIL,
  UPLOAD_CANDIDATE_DOCUMENT_SUCCESS,
  UPDATE_CANDIDATE_DOCUMENT_FAIL,
  UPDATE_CANDIDATE_DOCUMENT_SUCCESS,
  UPDATE_CANDIDATE_DOCUMENT,
  DELETE_CANDIDATE_DOCUMENT,
  DELETE_CANDIDATE_DOCUMENT_SUCCESS,
  DELETE_CANDIDATE_DOCUMENT_FAIL,
} from "./actionTypes"

//CANDIDATES
export const getCandidates = (searchText, page, sort) => ({
  type: GET_CANDIDATES,
  payload: { searchText, page, sort },
})

export const getCandidatesSuccess = candidates => ({
  type: GET_CANDIDATES_SUCCESS,
  payload: candidates,
})
export const getCandidatesFail = error => ({
  type: GET_CANDIDATES_FAIL,
  payload: error,
})

export const getCandidatePiachart = (candId, type, date) => ({
  type: GET_CANDIDATE_PIACHART,
  payload: { candId, type, date },
})

export const getCandidatePiachartSuccess = chartData => ({
  type: GET_CANDIDATE_PIACHART_SUCCESS,
  payload: chartData,
})
export const getCandidatePiachartFail = error => ({
  type: GET_CANDIDATE_PIACHART_FAIL,
  payload: error,
})

export const getCandidateDetail = candId => ({
  type: GET_CANDIDATE_DETAIL,
  candId,
})

export const getCandidateDetailSuccess = candidateDetails => ({
  type: GET_CANDIDATE_DETAIL_SUCCESS,
  payload: candidateDetails,
})

export const getCandidateDetailFail = error => ({
  type: GET_CANDIDATE_DETAIL_FAIL,
  payload: error,
})

export const createCandidate = (candidate, history) => ({
  type: CREATE_CANDIDATE,
  payload: { candidate, history },
})

export const createCandidateSuccess = candidate => ({
  type: CREATE_CANDIDATE_SUCCESS,
  payload: candidate,
})

export const createCandidateFail = error => ({
  type: CREATE_CANDIDATE_FAIL,
  payload: error,
})

export const updateCandidate = (candidate, candId, history) => ({
  type: UPDATE_CANDIDATE,
  payload: { candidate, candId, history },
})

export const updateCandidateSuccess = candidate => ({
  type: UPDATE_CANDIDATE_SUCCESS,
  payload: candidate,
})

export const updateCandidateFail = error => ({
  type: UPDATE_CANDIDATE_FAIL,
  payload: error,
})

export const uploadCandidatePic = (candidate, candId) => ({
  type: UPLOAD_CANDIDATE_PIC,
  payload: { candidate, candId },
})

export const uploadCandidatePicSuccess = candidate => ({
  type: UPLOAD_CANDIDATE_PIC_SUCCESS,
  payload: candidate,
})

export const uploadCandidatePicFail = error => ({
  type: UPLOAD_CANDIDATE_PIC_FAIL,
  payload: error,
})

export const uploadCandidateDocuments = (
  candidate,
  documents,
  onCloseClick
) => ({
  type: UPLOAD_CANDIDATE_DOCUMENT,
  payload: { candidate, documents, onCloseClick },
})

export const uploadCandidateDocumentsSuccess = candidate => ({
  type: UPLOAD_CANDIDATE_DOCUMENT_SUCCESS,
  payload: candidate,
})

export const uploadCandidateDocumentsFail = error => ({
  type: UPLOAD_CANDIDATE_DOCUMENT_FAIL,
  payload: error,
})

export const updateCandidateDocuments = (
  candidate,
  documents,
  onCloseClick
) => ({
  type: UPDATE_CANDIDATE_DOCUMENT,
  payload: { candidate, documents, onCloseClick },
})

export const updateCandidateDocumentsSuccess = document => ({
  type: UPDATE_CANDIDATE_DOCUMENT_SUCCESS,
  payload: document,
})

export const updateCandidateDocumentsFail = error => ({
  type: UPDATE_CANDIDATE_DOCUMENT_FAIL,
  payload: error,
})

export const deleteCandidateDocuments = documentId => ({
  type: DELETE_CANDIDATE_DOCUMENT,
  payload: { documentId },
})

export const deleteCandidateDocumentsSuccess = document => ({
  type: DELETE_CANDIDATE_DOCUMENT_SUCCESS,
  payload: document,
})

export const deleteCandidateDocumentsFail = error => ({
  type: DELETE_CANDIDATE_DOCUMENT_FAIL,
  payload: error,
})

export const deleteCandidate = (candId, history) => ({
  type: DELETE_CANDIDATE,
  payload: { candId, history },
})

export const deleteCandidateSuccess = candidate => ({
  type: DELETE_CANDIDATE_SUCCESS,
  payload: candidate,
})

export const deleteCandidateFail = error => ({
  type: DELETE_CANDIDATE_FAIL,
  payload: error,
})

//agentes
export const getAgentes = (searchText, page) => ({
  type: GET_AGENTES,
  payload: { searchText, page },
})

export const getAgentesSuccess = agent => ({
  type: GET_AGENTES_SUCCESS,
  payload: agent,
})
export const getAgentessFail = error => ({
  type: GET_AGENTES_FAIL,
  payload: error,
})

export const getAgenteDetail = agentId => ({
  type: GET_AGENTE_DETAIL,
  agentId,
})

export const getAgenteDetailSuccess = agentDetails => ({
  type: GET_AGENTE_DETAIL_SUCCESS,
  payload: agentDetails,
})

export const getAgenteDetailFail = error => ({
  type: GET_AGENTE_DETAIL_FAIL,
  payload: error,
})

export const createAgente = (agent, history) => ({
  type: CREATE_AGENTE,
  payload: { agent, history },
})

export const createAgenteSuccess = agent => ({
  type: CREATE_AGENTE_SUCCESS,
  payload: agent,
})

export const createAgenteFail = error => ({
  type: CREATE_AGENTE_FAIL,
  payload: error,
})

export const updateAgente = (agent, agentId, history) => ({
  type: UPDATE_AGENTE,
  payload: { agent, agentId, history },
})

export const updateAgenteSuccess = agent => ({
  type: UPDATE_AGENTE_SUCCESS,
  payload: agent,
})

export const updateAgenteFail = error => ({
  type: UPDATE_AGENTE_FAIL,
  payload: error,
})

export const deleteAgente = (agentId, history) => ({
  type: DELETE_AGENTE,
  payload: { agentId, history },
})

export const deleteAgenteSuccess = agent => ({
  type: DELETE_AGENTE_SUCCESS,
  payload: agent,
})

export const deleteAgenteFail = error => ({
  type: DELETE_AGENTE_FAIL,
  payload: error,
})

//company
export const getCompanies = (searchText, page) => ({
  type: GET_COMPANIES,
  payload: { searchText, page },
})

export const getCompaniesSuccess = company => ({
  type: GET_COMPANIES_SUCCESS,
  payload: company,
})
export const getCompaniesFail = error => ({
  type: GET_COMPANIES_FAIL,
  payload: error,
})

export const getCompanyDetail = companyId => ({
  type: GET_COMPANY_DETAIL,
  companyId,
})

export const getCompanyDetailSuccess = companyDetails => ({
  type: GET_COMPANY_DETAIL_SUCCESS,
  payload: companyDetails,
})

export const getCompanyDetailFail = error => ({
  type: GET_COMPANY_DETAIL_FAIL,
  payload: error,
})

export const createCompany = (company, history) => ({
  type: CREATE_COMPANY,
  payload: { company, history },
})

export const createCompanySuccess = company => ({
  type: CREATE_COMPANY_SUCCESS,
  payload: company,
})

export const createCompanyFail = error => ({
  type: CREATE_COMPANY_FAIL,
  payload: error,
})

export const updateCompany = (company, companyId) => ({
  type: UPDATE_COMPANY,
  payload: { company, companyId },
})

export const updateCompanySuccess = company => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: company,
})

export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
})

export const deleteCompany = (companyId, history) => ({
  type: DELETE_COMPANY,
  payload: { companyId, history },
})

export const deleteCompanySuccess = company => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: company,
})

export const deleteCompanyFail = error => ({
  type: DELETE_COMPANY_FAIL,
  payload: error,
})
