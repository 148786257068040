import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalBody,
} from "reactstrap"
import Dropzone from "react-dropzone"

import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  uploadCandidatePicFail,
  uploadCandidatePicSuccess,
} from "store/actions"
import PropTypes from "prop-types"
import { BASE_URL } from "helpers/api_methods"
import axios from "axios"
import { Notification } from "components/Common/Notification"

const ImageUpload = ({ isOpen, onClose, candidateId }) => {
  const dispatch = useDispatch()
  const [selectedFiles, setselectedFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    image: null,
  })

  function handleAcceptedFiles(files) {
    setState({
      image: files,
    })

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleImageUpload = () => {
    setLoading(true)

    const form_data = new FormData()

    {
      state?.image &&
        state?.image[0] &&
        form_data.append("image", state?.image[0], state?.image[0]?.name)
    }

    let url = `${BASE_URL}/api/v1/worker/worker/${candidateId}/`
    axios
      .patch(url, form_data, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then(res => {
        dispatch(uploadCandidatePicSuccess(res.data))
        setLoading(false)
        Notification({
          type: "success",
          message: "Image Uploaded Successfully",
          title: "",
        })
        onClose(false)
      })
      .catch(err => {
        uploadCandidatePicFail(err)
        setLoading(false)

        Notification({
          type: "error",
          message: "Upload image faild",
          title: "",
        })
      })
  }

  const onCloseClick = () => {
    onClose(false)
  }

  return (
    <Modal isOpen={isOpen} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col className="col-12">
            <CardBody>
              <CardTitle>Upload profile pic</CardTitle>
              <CardSubtitle className="mb-3">
                {" "}
                Drop files here or click to upload
              </CardSubtitle>
              <Form>
                <Dropzone
                  onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles)
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-2"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFiles.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )
                  })}
                </div>
              </Form>

              <div className="text-center mt-4">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => handleImageUpload()}
                >
                  Upload Image{" "}
                  {loading && (
                    <>
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </>
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-light mx-2"
                  onClick={onCloseClick}
                >
                  Not now
                </button>
              </div>
            </CardBody>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ImageUpload
ImageUpload.propTypes = {
  candidateId: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
