import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import { debounce } from "lodash"

//actions
import {
  getAccountCategory,
  getAccountDetail,
  getCandidates,
  updateAccount,
} from "store/actions"
import MetaTag from "constants/Meta-tag"
import moment from "moment"

const UpdateAccount = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const {
    accountDetail,
    loading,
    error,
    accountCategory,
    accountCategoryLoad,
    candidates,
    candidatesLoad,
    accountIncome,
    accountExpense,
  } = useSelector(state => ({
    loading: state.Accounts.loading,
    error: state.Accounts.error,
    accountDetail: state.Accounts.accountDetail,
    candidates: state.Users.candidates,
    candidatesLoad: state.Users.candidatesLoad,
    accountCategory: state.Accounts.accountCategory,
    accountCategoryLoad: state.Accounts.accountCategoryLoad,
    accountIncome: state.Accounts.accountIncome,
    accountExpense: state.Accounts.accountExpense,
  }))

  const Types = ["worker", "company"]

  const [transaction_type, setTransaction_type] = useState("credit")
  const [sort, setSort] = useState("credit")

  //account_category
  const [accCatSelect, setAccCatSelect] = useState()
  const [accCatSearch, setAccCatSearch] = useState()
  const [accCatId, setAccCatId] = useState()

  const debounceAccCatSearch = debounce(value => setAccCatSearch(value), 600)

  const handleAccCatEnters = textEntered => {
    debounceAccCatSearch(textEntered)
  }

  function handlerAccCatFinalValue(event) {
    const accCat = {
      ...companySelect,
      title: event.label,
      id: event.value,
    }
    setAccCatSelect(accCat)
    setAccCatId(event.value)
  }

  const handleCat = () => {
    if (sort === "credit") {
      return accountIncome
    } else if (sort === "debit") {
      return accountExpense
    } else return accountCategory
  }

  const accCatOptions = [
    {
      options: handleCat()?.results?.map((result, index) => ({
        key: index,
        label: result.category_name,
        value: result.id,
      })),
    },
  ]

  //worker is candidate
  const [workerSelect, setWorkerSelect] = useState()
  const [workerSearch, setWorkerSearch] = useState()
  const [workerId, setWorkerId] = useState()

  const debounceWorkerSearch = debounce(value => setWorkerSearch(value), 600)

  const handleWorkerEnters = textEntered => {
    debounceWorkerSearch(textEntered)
  }

  function handlerWorkerFinalValue(event) {
    const worker = {
      ...companySelect,
      title: event.label,
      id: event.value,
    }
    setWorkerSelect(worker)
    setWorkerId(event.value)
  }

  const workerOptions = [
    {
      options: candidates?.results?.map((result, index) => ({
        key: index,
        label: result?.given_name,
        value: result.id,
      })),
    },
  ]

  useEffect(() => {
    dispatch(getAccountCategory(accCatSearch, "", sort))
  }, [dispatch, accCatSearch, sort])

  useEffect(() => {
    dispatch(getCandidates(workerSearch))
  }, [dispatch, workerSearch])

  useEffect(() => {
    dispatch(getAccountDetail(params.id))
  }, [])

  useEffect(() => {
    const accCat = {
      title: accountDetail?.category_name,
      id: accountDetail?.account_category,
    }
    setAccCatSelect(accCat)
    setAccCatId(accountDetail?.account_category)

    const worker = {
      title: accountDetail?.worker_name,
      id: accountDetail?.worker,
    }
    setWorkerSelect(worker)
    setWorkerId(accountDetail?.worker)

    setTransaction_type(accountDetail?.transaction_type)
  }, [accountDetail])

  // handleValidSubmit
  function handleValidSubmit(e, values) {
    const finalData = {
      ...values,
      account_category: accCatId,
      worker: workerId,
      // company: companyId,
      transaction_type: transaction_type,
    }
    console.log(finalData)
    dispatch(updateAccount(finalData, accountDetail.id, history))
  }

  const handleSort = type => {
    if (type === "credit") {
      setSort("income")
      setTransaction_type("credit")
    } else {
      setSort("expense")
      setTransaction_type("debit")
    }
  }

  return (
    <>
      <MetaTag title="Accounts" />

      <div className="page-content">
        <Breadcrumbs title="Accounts" breadcrumbItem="Update Account" />
        <Container fluid>
          <div className="container-fluid">
            <div className="sm:p-0" id="update">
              <Row>
                <Col xl="3"></Col>

                <Col lg={6} className="sm:p-0">
                  <Card className="p-3 sm:p-0">
                    <CardBody>
                      <AvForm
                        className="form-horizontal "
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-username-Input"
                            className="col-sm-4 col-form-label"
                          ></Label>
                          <Col sm={8}>
                            <div
                              className="btn-group btn-group-example"
                              role="group"
                            >
                              <button
                                type="button"
                                className={`btn btn-outline-success w-sm ${transaction_type === "credit"
                                  ? "bg-success text-white"
                                  : ""
                                  } `}
                                onClick={() => handleSort("credit")}
                              >
                                Credit
                              </button>
                              <button
                                type="button"
                                onClick={() => handleSort("debit")}
                                className={`btn btn-outline-danger w-sm ${transaction_type === "debit"
                                  ? "bg-danger text-white"
                                  : ""
                                  } `}
                              >
                                Debit
                              </button>
                            </div>
                          </Col>
                        </div>
                        {/* <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-username-Input"
                            className="col-sm-4 col-form-label"
                          >
                            Type
                          </Label>
                          <Col sm={8}>
                            <AvField
                              name="type"
                              type="select"
                              className="text-muted"
                              value={accountDetail?.type}
                            >
                              <option>Select...</option>
                              {Types?.map((item, key) => (
                                <option key={key} value={item}>
                                  {item}
                                </option>
                              ))}
                            </AvField>
                          </Col>
                        </div> */}
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-date-Input"
                            className="col-sm-4 col-form-label"
                          >
                            Date
                          </Label>
                          <Col sm={8}>
                            <AvField
                              id={`input-id-date`}
                              className="filePathClass"
                              name={"date"}
                              type={"date"}
                              validate={{
                                date: { format: "YYYY-MM-DD" },
                                required: { value: false },
                              }}
                              value={accountDetail?.date}
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-username-Input"
                            className="col-sm-4 col-form-label"
                          >
                            Account category
                          </Label>
                          <Col sm={8}>
                            <FormGroup className="w-100 custom_store_select">
                              <div className="ajax-select mt-lg-0 select2-container new_custom_store_select">
                                <Select
                                  onInputChange={handleAccCatEnters}
                                  value={accCatSelect?.title}
                                  placeholder={accCatSelect?.title}
                                  onChange={handlerAccCatFinalValue}
                                  options={accCatOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={accountCategoryLoad}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-email-Input"
                            className="col-sm-4 col-form-label"
                          >
                            Candidate
                          </Label>
                          <Col sm={8}>
                            <FormGroup className="w-100 custom_store_select">
                              <div className="ajax-select mt-lg-0 select2-container new_custom_store_select">
                                <Select
                                  onInputChange={handleWorkerEnters}
                                  value={workerSelect?.title}
                                  placeholder={workerSelect?.title}
                                  onChange={handlerWorkerFinalValue}
                                  options={workerOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={candidatesLoad}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </div>
                        {/* <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-username-Input"
                            className="col-sm-4 col-form-label"
                          >
                            Company
                          </Label>
                          <Col sm={8}>
                            <FormGroup className="w-100 custom_store_select">
                              <div className="ajax-select mt-lg-0 select2-container new_custom_store_select">
                                <Select
                                  onInputChange={handleCompanyEnters}
                                  value={companySelect?.title}
                                  placeholder={companySelect?.title}
                                  onChange={handlerCompanyFinalValue}
                                  options={companyOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={companyLoad}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </div> */}
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-username-Input"
                            className="col-sm-4 col-form-label"
                          >
                            Amount
                          </Label>
                          <Col sm={8}>
                            <AvField
                              id="horizontal-username-Input"
                              className="filePathClass"
                              name="amount"
                              type="number"
                              value={accountDetail?.amount}
                            />
                          </Col>
                        </div>
                        {/* <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-username-Input"
                            className="col-sm-4 col-form-label"
                          >
                            Transaction Type
                          </Label>
                          <Col sm={8}>
                            <AvField
                              type="select"
                              name="transaction_type"
                              className="text-muted"
                              style={{ textTransform: "capitalize" }}
                              value={accountDetail?.transaction_type}
                            >
                              <option>Select...</option>
                              {transType?.map((item, key) => (
                                <option key={key} value={item}>
                                  {item}
                                </option>
                              ))}
                            </AvField>
                          </Col>
                        </div> */}
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-email-Input"
                            className="col-sm-4 col-form-label"
                          >
                            description
                          </Label>
                          <Col sm={8}>
                            <AvField
                              id="horizontal-email-Input"
                              name="description"
                              className="form-control"
                              value={accountDetail?.description}
                              type="textarea"
                            />
                          </Col>
                        </div>

                        <div className="row justify-content-end">
                          <Col sm={8}>
                            <div>
                              <Button
                                type="submit"
                                color="success"
                                className="w-md"
                              >
                                {loading && (
                                  <>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                  </>
                                )}
                                Update
                              </Button>
                            </div>
                          </Col>
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="3"></Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateAccount

UpdateAccount.propTypes = {
  history: PropTypes.object,
}
