import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory } from "react-router"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import { getCompanyDetail, updateCompany } from "store/actions"
import MetaTag from "constants/Meta-tag"
import Candidate from "pages/candidates/DatatableTables"

const UpdateCompany = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const { singleCandidate, loading, error } = useSelector(state => ({
    loading: state.Users.companyLoad,
    error: state.Users.error,
    singleCandidate: state.Users.singleCompany,
  }))
  function handleValidSubmit(values) {
    dispatch(updateCompany(values, singleCandidate.id))
    history.goBack()
  }

  useEffect(() => {
    dispatch(getCompanyDetail(params.id))
  }, [])

  return (
    <>
      <MetaTag title="Companies" />

      <div className="page-content">
        <Breadcrumbs title="Companies" breadcrumbItem="Update Company" />
        <Container fluid>
          <div className="container-fluid">
            <div className="sm:p-0" id="update">
              <Row>
                <Col lg={6} className="sm:p-0">
                  <Card className="p-3 sm:p-0">
                    <CardBody>
                      <AvForm
                        className="form-horizontal "
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(v)
                        }}
                      >
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-username-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Company Name
                          </Label>
                          <Col sm={9}>
                            <AvField
                              id="horizontal-username-Input"
                              className="filePathClass"
                              name="company_name"
                              type="text"
                              value={singleCandidate?.company_name}
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="tel-input"
                            className="col-sm-3 col-form-label"
                          >
                            Phone No
                          </Label>
                          <Col sm={9}>
                            <AvField
                              name="contact"
                              className="form-control"
                              id="tel-input"
                              value={singleCandidate?.contact}
                              type="mobile"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage:
                                    "Please enter your phone number",
                                },
                                minLength: {
                                  value: 10,
                                  errorMessage:
                                    "Your number must be 10 characters",
                                },
                                maxLength: {
                                  value: 10,
                                  errorMessage:
                                    "Your number must be 10 characters",
                                },
                              }}
                            />
                          </Col>
                        </div>

                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-email-Input"
                            className="col-sm-3 col-form-label"
                          >
                            Address
                          </Label>
                          <Col sm={9}>
                            <AvField
                              id="horizontal-email-Input"
                              name="address"
                              value={singleCandidate?.address}
                              className="form-control"
                              type="textarea"
                            />
                          </Col>
                        </div>
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-email-Input"
                            className="col-sm-3 col-form-label"
                          >
                            note
                          </Label>
                          <Col sm={9}>
                            <AvField
                              id="horizontal-email-Input"
                              value={singleCandidate?.note}
                              name="note"
                              className="form-control"
                              type="textarea"
                            />
                          </Col>
                        </div>

                        <div className="row justify-content-end">
                          <Col sm={9}>
                            <div>
                              <Button
                                type="submit"
                                color="success"
                                className="w-md"
                              >
                                {loading && (
                                  <>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                  </>
                                )}
                                Update
                              </Button>
                            </div>
                          </Col>
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12}>
                  <CardTitle>Candidates</CardTitle>
                  <Candidate isInner={true} />
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateCompany

UpdateCompany.propTypes = {
  history: PropTypes.object,
}
