import PropTypes from "prop-types"
import React, { Fragment, useEffect, useRef } from "react"
import { map } from "lodash"
import { mySideBar } from "./sidebarData"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { useHistory, withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const history = useHistory()

  const pathName = props.location.pathname

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link
                to="/#"
                className={pathName === "/dashboard" ? "text-info" : ""}
              >
                <i
                  className={`bx bxs-dashboard ${
                    pathName === "/dashboard" ? "text-info" : ""
                  }`}
                ></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            {map(mySideBar, (item, index) => (
              <Fragment key={index}>
                {item?.headingSaparetor ? (
                  <li className="menu-title mt-3">
                    {props.t(`${item?.headingSaparetor}`)}{" "}
                  </li>
                ) : (
                  <li>
                    <div onClick={() => history.push(item.routeLink)}>
                      <Link
                        to="/#"
                        className={`${
                          pathName === item?.routeLink ? "text-info" : ""
                        }`}
                      >
                        <i
                          className={`${item.iconClass}  ${
                            pathName === item?.routeLink ? " text-info" : ""
                          }`}
                        ></i>
                        <span>{props.t(`${item?.heading}`)}</span>
                        <span
                          className={`badge rounded-pill float-end bg-${item?.badgeClass}`}
                        >
                          {item?.badgeValue}
                        </span>
                      </Link>
                    </div>
                    {/* 
                  <ul className="sub-menu" aria-expanded="false">
                    {map(item?.subTitles, (title, index1) => (
                      <li key={index1}>
                        <Link to={title?.routeLink}>
                          {props.t(`${title?.title}`)}
                        </Link>
                      </li>
                    ))}
                  </ul> */}
                  </li>
                )}
              </Fragment>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
