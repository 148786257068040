import { AvField, AvForm } from "availity-reactstrap-validation"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import { createAgente } from "store/actions"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import MetaTag from "constants/Meta-tag"

const CreateAgent = ({ history }) => {
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => ({
    loading: state.Users.candidatesLoad,
    error: state.Users.error,
  }))

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    console.log(values)
    dispatch(createAgente(values, history))
  }

  const country = ["India", "Malta"]

  return (
    <>
      <MetaTag title="Candidate" />

      <div className="page-content">
        <Breadcrumbs title="Agents" breadcrumbItem="Create Agents" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col xl="3"></Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Fill this form</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-username-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Agent Name
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-username-Input"
                            className="filePathClass"
                            name="agent_name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="tel-input"
                          className="col-sm-3 col-form-label"
                        >
                          Phone No
                        </Label>

                        <Col sm={9}>
                          <AvField
                            name="contact"
                            className="form-control"
                            id="tel-input"
                            type="mobile"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter your phone number",
                              },
                              minLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                              maxLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Email
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-email-Input"
                            name="email"
                            className="form-control"
                            type="email"
                            required
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-username-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Country
                        </Label>
                        <Col sm={9}>
                          <AvField type={"select"} name="country">
                            <option>Select</option>
                            {country?.map((item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </AvField>
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Address
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-email-Input"
                            name="address"
                            className="form-control"
                            type="textarea"
                          />
                        </Col>
                      </div>

                      <div className="row justify-content-end">
                        <Col sm={9}>
                          <div>
                            <Button
                              type="submit"
                              color="success"
                              className="w-md"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3"></Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateAgent

CreateAgent.propTypes = {
  history: PropTypes.object,
}
